const categories = [
  { text: "monitor details", key: "monitordetails" },
  { text: "appearance", key: "appearance", skip: true },
  { text: "general settings", key: "general settings", skip: true },
  { text: "notifications", key: "notifications" },
  { text: "integrations & team", key: "integrationsteam" },
  { text: "integrations", key: "integrations" },
  { text: "maintenance", key: "maintenance" },
];

const DropdownVariables = [{ value: "roboto", text: "Roboto" }];
const DropdownVariablesTheme = [
  { value: "dark", text: "Dark" },
  { value: "light", text: "Light" },
];

const robotsMetaOptions = [
  { value: "index", text: "Index" },
  { value: "noindex", text: "No Index" },
  { value: "follow", text: "Follow" },
  { value: "nofollow", text: "No Follow" },
  { value: "all", text: "All" },
  { value: "none", text: "None" },
  { value: "noarchive", text: "No Archive" },
  { value: "nosnippet", text: "No Snippet" },
  { value: "noodp", text: "No ODP" },
  { value: "noimageindex", text: "No Image Index" },
  { value: "notranslate", text: "No Translate" },
];

const countries = [
  {
    key: "helsinki",
    value: "Helsinki/Finland",
    img: "../images/countries/finland.png",
  },
  {
    key: "nuremberg",
    value: "Nuremberg/Germany",
    img: "../images/countries/germany.png",
  },
];

const SortVariables = [
  { value: "default", key: "Default" },
  { value: "down", key: "Down first" },
  { value: "up", key: "Up first" },
  { value: "az", key: "A → Z" },
  { value: "za", key: "Z → A" },
  { value: "newest", key: "Newest first" },
  { value: "oldest", key: "Oldest first" },
  { value: "control", key: "Control time first" },
  { value: "high", key: "High duration first" },
  { value: "low", key: "Low duration first" },
];

const FilterVariables = [
  { value: "up", key: "Up" },
  { value: "down", key: "Down" },
  { value: "expiring_domain", key: "Expiring Domain" },
  { value: "expiring_ssl", key: "Expiring SSL Certificate" },
  { value: "inactive", key: "Inactive" },
  { value: "public", key: "State Public" },
  { value: "private", key: "State Private" },
  { value: "https", key: "HTTP monitoring" },
  { value: "keyword", key: "Keyword monitoring" },
  { value: "ping", key: "Ping monitoring" },
  { value: "port", key: "Port monitoring" },
  { value: "cronjob", key: "Cronjob monitoring" },
];

const SortIncidents = [
  { value: "resolved", key: "Down" },
  { value: "message", key: "Alert Messages" },
];

module.exports = {
  categories,
  DropdownVariables,
  DropdownVariablesTheme,
  robotsMetaOptions,
  countries,
  SortVariables,
  FilterVariables,
  SortIncidents,
};
