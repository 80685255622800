import React from "react";
import { useLocation } from "react-router-dom";
import { Box, List } from "@mui/material";
import Menuitems from "./MenuItems";
import NavItem from "./NavItem";
import NavGroup from "./NavGroup";
import Upgrade from "./Upgrade"; // Update this import if necessary
import { Link } from "react-router-dom";

const SidebarItems = ({ toggleMobileSidebar }) => {
  const location = useLocation();
  const pathDirect = location.pathname;

  return (
    <Box sx={{ px: 3, height: "100%" }}>
      <Box className="container">
        <Link
          to="/"
          style={{ display: "inline-block", textDecoration: "none", py: 0 }}
        >
          <Box
            component="img"
            className="top-div"
            sx={{
              height: 220,
              width: 221,
              maxHeight: { xs: 221, md: 167 },
              maxWidth: { xs: 221, md: 250 },
              px: 0,
              py: 0,
            }}
            alt="Statusnap Logo"
            src={
              (pathDirect.match(/\//g) || []).length == 1
                ? "./images/logo.svg"
                : "../images/logo.svg"
            }
          />
        </Link>
        <List sx={{ pt: 0 }} className="sidebarNav" component="div">
          {Menuitems.map((item) => {
            if (item.subheader) {
              return <NavGroup item={item} key={item.subheader} />;
            } else {
              return (
                <NavItem
                  item={item}
                  key={item.id}
                  pathDirect={pathDirect}
                  // onClick={(toggleMobileSidebar)}
                />
              );
            }
          })}
        </List>
      </Box>

      <Box sx={{ sm: { height: "20%" } }}></Box>
      {/* <Upgrade /> */}
    </Box>
  );
};

export default SidebarItems;
