import React, { Component } from "react";
import { connect } from "react-redux";
import {
  AddButton,
  AvailableOnlyAlert,
  DownloadButton,
} from "../helper/Buttons";
import { ComingSoon } from "../helper/Backgrounds";
import { Box, Typography } from "@mui/material";

class TeamAlert extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    document.title = "Team | Statusnap";
  }

  render() {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          textAlign: "center",
          flexDirection: "column",
          height: window.innerHeight / 1.3,
        }}
      >
        <Typography variant="h6" sx={{ color: "white" }}>
          This feature is currently unavailable.
        </Typography>
        <Typography variant="h6" sx={{ color: "white" }}>
          It will be available soon.
        </Typography>
        <Box
          component="img"
          src="./images/logo.svg"
          alt="Coming Soon"
          sx={{ width: 200, height: 200 }}
        />
        <ComingSoon />
      </Box>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
}))(TeamAlert);
