import { put, call, takeLatest } from "redux-saga/effects";
import * as ActionTypes from "../constants/actionTypes";
import { get } from "../util/api";
import lGet from "lodash/get";

function* product(action) {
  try {
    let type = action.product_type || "statusnap";
    const response = yield call(get, `/${type}/product`);
    if (!response || !response.success)
      return put({ type: ActionTypes.PRODUCT_FAILED, error: "Load Failed." });

    yield put({
      type: ActionTypes.PRODUCT_SUCCEEDED,
      product: response.product,
    });
  } catch (error) {
    if (lGet(error.response, "data")) {
      yield put({
        type: ActionTypes.PRODUCT_FAILED,
        error: error.response.data,
      });
    } else {
      yield put({ type: ActionTypes.PRODUCT_FAILED, error });
    }
  }
}

export function* watchProduct() {
  yield takeLatest(ActionTypes.PRODUCT_REQUESTED, product);
}
