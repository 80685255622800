import React, { Component } from "react";
import { connect } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import { SubscriptionSuccess } from "../helper/Backgrounds";
import { Link } from "react-router-dom";

class SubscriptionSuccessfull extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }
  componentDidMount() {
    document.title = "Success Payment | Statusnap";
  }

  render() {
    const getNextMonthDate = () => {
      const currentDate = new Date();
      const nextMonthDate = new Date();
      nextMonthDate.setMonth(currentDate.getMonth() + 1);
      return nextMonthDate.toDateString();
    };
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          height: window.innerHeight / 1.3,
        }}
      >
        <SubscriptionSuccess />
        <Typography variant="h5" gutterBottom style={{ color: "white" }}>
          Thank You!
        </Typography>
        <Typography variant="body1" gutterBottom style={{ color: "white" }}>
          Payment done Successfully!
        </Typography>
        <Typography variant="body2" gutterBottom style={{ color: "white" }}>
          Thank you for processing your most recent payment. Your premium
          subscription will expire on {getNextMonthDate()}.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/monitors"
          style={{ color: "white", marginTop: 30 }}
        >
          Status Page
        </Button>
      </Box>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
}))(SubscriptionSuccessfull);
