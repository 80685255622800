import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Link,
  Paper,
  IconButton,
  Button,
  MenuItem,
  Select,
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Zoom,
  Fab,
  Alert,
} from "@mui/material";
import _ from "lodash";
import { BackButton } from "../helper/Buttons";
import { Link as RouterLink } from "react-router-dom";
import DashboardCard from "../components/dashboard/dashboardCard";
import { IconHttpDelete, IconUpload } from "@tabler/icons-react";
import { MuiColorInput } from "mui-color-input";
import {
  categories,
  DropdownVariables,
  DropdownVariablesTheme,
  robotsMetaOptions,
} from "../helper/detailSettings";
import { IOSSwitch } from "../helper/inputs";

const label = { inputProps: { "aria-label": "Color switch demo" } };

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      skipDesign: false,
      id: this.props.match.params.id || null,
      value: "monitordetails",
      redirect: false,
      settings: {},
      logo: "",
      favicon: "",
      font: "roboto",
      layout: "",
      title: "",
      description: "",
      homepageUrl: "",
      headerBgColor: "#000000",
      headerTextColor: "#FFFFFF",
      linkColor: "#0000FF",
      customDomain: "",
      googleAnalytics: "",
      theme: "dark",
    };
  }

  componentDidMount() {
    this.findTemplate();
  }

  handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 150 * 1024) {
      this.setState({ logo: URL.createObjectURL(file) });
    } else {
      alert("File size should be less than 150kb");
    }
  };

  handleFaviconUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 150 * 1024) {
      this.setState({ favicon: URL.createObjectURL(file) });
    } else {
      alert("File size should be less than 150kb");
    }
  };

  async findTemplate() {
    let { user } = this.props;
    let { servers } = user;
    let found = _.find(servers, (e) => e.id == this.state.id);
    if ((!this.state.id || this.state.id != "create") && !found) {
      this.setState({ redirect: true });
    } else {
      if (this.state.id == "create") {
        document.title = "Create Status Page | Statusnap";
      } else {
        document.title = "Edit Status Page | Statusnap";
        this.setSettings(found);
      }
      this.setState({ settings: found, loading: false });
    }
  }

  setSettings = (settings) => {
    let { client_settings } = settings;
    let description = client_settings && client_settings.description;
    let favicon = client_settings && client_settings.favicon;
    let logo = client_settings && client_settings.logo;
    let logo_settings = client_settings && client_settings.logo_settings;
    let robots = client_settings && client_settings.robots;
    let title = client_settings && client_settings.title;
    let googleTrackingID = client_settings && client_settings.googleTrackingID;
    let timeUntilRefresh = client_settings && client_settings.timeUntilRefresh;
    let simple = client_settings && client_settings.simple;
    let theme = (client_settings && client_settings.dark_mode) || "light";
    let skipDesign = (client_settings && client_settings.skip_design) || false;

    this.setState({
      description,
      favicon,
      logo,
      robots,
      title,
      googleAnalytics: googleTrackingID,
      timeUntilRefresh,
      theme,
      skipDesign,
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleChangeLayout = (event) => {
    const { name } = event.target;
    this.setState({ layout: name });
  };

  renderTextField = (name, label, placeholder) => (
    <>
      <Typography variant="subtitle1" sx={{ color: "white", marginTop: 2 }}>
        {label}
      </Typography>
      <TextField
        margin="normal"
        fullWidth
        id={name}
        name={name}
        placeholder={placeholder}
        value={this.state[name]}
        onChange={this.handleChange}
        InputProps={{
          style: { color: "white", caretColor: "white" },
        }}
        sx={{
          margin: 0,
          "& .MuiOutlinedInput-root": {
            "& fieldset": { borderColor: "#c9c9c9" },
            "&:hover fieldset": { borderColor: "#151f2f" },
          },
          "& .MuiInputLabel-root": { color: "#c9c9c9" },
          "& .MuiFormHelperText-root": { color: "#c9c9c9" },
        }}
      />
    </>
  );

  renderColorPicker = (name, label) => (
    <>
      <Typography
        variant="subtitle1"
        sx={{ color: "white", marginTop: 2, marginBottom: 1 }}
      >
        {label}
      </Typography>
      <MuiColorInput
        format="hex"
        value={this.state[name]}
        onChange={(color) => this.setState({ [name]: color })}
      />
    </>
  );

  renderDropdown = (name, label, options) => (
    <>
      <Typography
        variant="subtitle1"
        sx={{ color: "white", marginTop: 2, marginBottom: 1 }}
      >
        {label}
      </Typography>
      <FormControl
        sx={{
          width: "100%",
          color: "white",
          marginTop: "5px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": { borderColor: "#c9c9c9" },
            "&:hover fieldset": { borderColor: "#15a32a" },
          },
          "& .MuiInputLabel-root": { color: "#c9c9c9" },
          "& .MuiFormHelperText-root": { color: "#c9c9c9" },
        }}
      >
        <Select
          sx={{
            color: "white",
            height: 35,
            "&:hover": { color: "#c9c9c9" },
          }}
          value={this.state[name]}
          onChange={this.handleChange}
          name={name}
        >
          {options.map((option, i) => (
            <MenuItem
              sx={{ color: "white" }}
              value={option.value}
              key={`dropdown-${name}-${i}`}
            >
              {option.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );

  renderImageUpload = (type, title, description) => (
    <Paper
      sx={{
        flex: 1,
        p: 2,
        bgcolor: "#151f2f",
        marginRight: 1,
        marginBottom: 1,
      }}
    >
      <Typography variant="subtitle1" color="white">
        {title}
      </Typography>
      <Typography variant="caption" sx={{ color: "gray" }}>
        {description}
      </Typography>
      <Box
        sx={{
          mt: 2,
          height: 100,
          border: "1px dashed gray",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {this.state[type] ? (
          <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
            <img
              src={this.state[type]}
              alt={`${type}-preview`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
            <IconButton
              sx={{ position: "absolute", top: 0, right: 0, color: "red" }}
              onClick={() => this.setState({ [type]: "" })}
            >
              <IconHttpDelete />
            </IconButton>
          </Box>
        ) : (
          <Button component="label" startIcon={<IconUpload />}>
            <span style={{ color: "white" }}>Upload new</span>
            <input
              type="file"
              hidden
              onChange={
                type === "logo"
                  ? this.handleLogoUpload
                  : this.handleFaviconUpload
              }
              accept={type === "logo" ? ".jpg,.jpeg,.png" : ".png,.gif,.ico"}
            />
          </Button>
        )}
      </Box>
    </Paper>
  );

  renderAppearanceSection = () => (
    <>
      <Box mb={2}>
        <DashboardCard title="Branding">
          <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
            {this.renderImageUpload(
              "logo",
              "Logo",
              "Accepted formats are .jpg, .jpeg or .png. Only less than 400x200px and 150kb size allowed."
            )}
            {this.renderImageUpload(
              "favicon",
              "Favicon",
              "Accepted formats are .png, .gif or .ico. Only less than 96x96px and 150kb size allowed."
            )}
          </Box>
          {this.renderTextField("title", "Title", "Statusnap Status Page")}
          {this.renderTextField(
            "description",
            "Description",
            "The official status page for services offered by Statusnap."
          )}
          {this.renderTextField(
            "homepageUrl",
            "Homepage URL",
            "https://www.statusnap.com/"
          )}
          {this.renderDropdown("font", "Font Family", DropdownVariables)}
        </DashboardCard>
      </Box>
      <Box mb={2}>
        <DashboardCard title="Layout">
          <Box display="flex" justifyContent="center" alignItems="center">
            <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
              {["Basic", "Detailed"].map((layoutType) => (
                <Box
                  key={layoutType}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 2,
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 200,
                      width: 300,
                      maxHeight: { xs: 200, md: 200 },
                      maxWidth: { xs: 300, md: 300 },
                      objectFit: "contain",
                    }}
                    alt={layoutType}
                    src={`../images/${layoutType.toLowerCase()}.png`}
                  />
                  <FormControlLabel
                    sx={{ color: "white" }}
                    control={
                      <Checkbox
                        sx={{ color: "white" }}
                        checked={this.state.layout === layoutType.toLowerCase()}
                        onChange={this.handleChangeLayout}
                        name={layoutType}
                      />
                    }
                    label={layoutType}
                  />
                </Box>
              ))}
            </FormGroup>
          </Box>
        </DashboardCard>
      </Box>
      <Box mb={2}>
        <DashboardCard title="Colors">
          {this.renderDropdown("theme", "Theme", DropdownVariablesTheme)}
          {this.renderColorPicker("headerBgColor", "Header background color")}
          {this.renderColorPicker("headerTextColor", "Header text color")}
          {this.renderColorPicker("linkColor", "Link color")}
        </DashboardCard>
      </Box>
    </>
  );

  renderGeneralSettingsSection = () => (
    <>
      <Box mb={2}>
        <DashboardCard title="White-label">
          {this.renderTextField(
            "customDomain",
            "Custom domain",
            "https://status.yourcompany.com"
          )}
          {this.renderTextField(
            "googleAnalytics",
            "Google analytics",
            "UA-XXXXXXXXX-X"
          )}
          <Typography variant="subtitle1" sx={{ color: "white", marginTop: 2 }}>
            Remove Statusnap Logo
          </Typography>
          <IOSSwitch
            props={{ defaultChecked: false, ...label }}
            onChange={() => {
              this.setState({
                skipDesign: !this.state.skipDesign,
                value: "monitordetails",
              });
            }}
          />
        </DashboardCard>
      </Box>
      <Box mb={2}>
        <DashboardCard title="Access restriction">
          {this.renderTextField(
            "passwordProtection",
            "Password Protection",
            "*******"
          )}
          {/* Access restriction fields can be added here */}
          {this.renderDropdown("robots", "Robots meta tag", robotsMetaOptions)}
        </DashboardCard>
      </Box>
    </>
  );

  render() {
    let { value, redirect, settings, skipDesign } = this.state;

    if (redirect) return <Redirect to={{ pathname: "/monitors" }} />;
    if (this.state.loading) return <div>Loading...</div>;

    return (
      <>
        <Box pb={2}>
          <BackButton
            text="Monitors"
            disableElevation
            component={RouterLink}
            to="/monitors"
          />
          {settings &&
            settings.server_settings &&
            settings.server_settings.link && (
              <Typography
                sx={{ color: "white", fontSize: "25px", marginTop: "12px" }}
              >
                Edit{" "}
                <Typography
                  sx={{ display: "inline", fontSize: "25px", color: "#15a32a" }}
                >
                  {settings.server_settings.link}
                </Typography>{" "}
                status page.
              </Typography>
            )}
          {settings && settings.id && (
            <>
              <Typography
                sx={{ display: "inline", color: "white", fontSize: "12px" }}
              >
                Public status page, hosted on{" "}
              </Typography>
              <Typography
                sx={{ display: "inline", fontSize: "12px", color: "#15a32a" }}
              >
                <Link
                  href={`https://dns.statusnap.com?p=${settings.id}`}
                  target="_blank"
                  sx={{ color: "#15a32a" }}
                >
                  https://dns.statusnap.com?p={settings.id}
                </Link>
              </Typography>
            </>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", margin: "10px 0" }}>
            <Alert variant="filled" severity="warning">
              If you want to <b>list multiple monitors in the same page</b> using the{" "}
              <b>Status Cluster</b> feature, you can skip the design steps. For
              listing multiple items, save the monitor and proceed to the Status
              Cluster feature. If you want to list it as a separate page, follow
              the design steps.{" "}
              <Typography
                sx={{
                  marginRight: "5px",
                  marginTop: "5px",
                  color: "#151f2f",
                  display: "flex",
                  flexDirection: "row",
                }}
                onClick={() => {
                  this.setState({ designAlert: true });
                }}
              >
                <span
                  style={{
                    marginRight: "5px",
                    color: "white",
                    fontWeight: "800",
                  }}
                >
                  Skip design
                </span>
                <IOSSwitch
                  props={{ defaultChecked: false, ...label }}
                  onChange={() => {
                    this.setState({
                      skipDesign: !this.state.skipDesign,
                      value: "monitordetails",
                    });
                  }}
                />
              </Typography>
            </Alert>

            {/* <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              color="white"
              open={this.state.designAlert}
              autoHideDuration={10000}
              onClose={() => this.setState({ designAlert: false })}
              message="If you want to list multiple monitors using the Status Cluster
            feature, you can skip the design steps. For listing multiple items,
            save the monitor and proceed to the Status Cluster feature. If you
            want to list it as a separate page, follow the design steps."
            /> */}
          </Box>
        </Box>
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
          <Box flex={{ xs: "1 1 auto", md: "0 0 75%" }} pr={2}>
            {value === "appearance" && this.renderAppearanceSection()}
            {value === "general settings" &&
              this.renderGeneralSettingsSection()}
            {/* Add other sections as needed */}
          </Box>
          <Box flex={{ xs: "1 1 auto", md: "0 0 25%" }} pb={2}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              sx={{ position: "fixed" }}
              onChange={(e, newValue) => this.setState({ value: newValue })}
              aria-label="Vertical tabs example"
              style={{ alignItems: "flex-start" }}
            >
              {categories
                .filter((e) => (skipDesign ? !e.skip : true))
                .map((e) => (
                  <Tab
                    key={e.key}
                    label={e.text}
                    value={e.key}
                    style={{ alignItems: "flex-start" }}
                    sx={{
                      color: (theme) => theme.palette.primary.secondTextColor,
                      fontWeight: "bold",
                      background: value === e.key ? "#131a25" : "transparent",
                      borderRadius: value === e.key ? "5px" : "none",
                      "&.Mui-selected": { color: "#c4c4c4" },
                    }}
                  />
                ))}
            </Tabs>
          </Box>
        </Box>
        <Zoom
          key={"white"}
          in={true}
          timeout={{ enter: 1, exit: 2 }}
          style={{
            transitionDelay: `${0}ms`,
          }}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            background: "#008000cd",
            color: "white",
            "&:hover": {
              backgroundColor: "#05e105cd",
            },
          }}
          unmountOnExit
        >
          <Fab aria-label={"Save"}>{"Save"}</Fab>
        </Zoom>
      </>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
}))(Detail);
