import React from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const FeatureComparison = () => {
  const features = [
    { name: "Feature 1", basic: true, standard: true, premium: true },
    { name: "Feature 2", basic: true, standard: true, premium: true },
    { name: "Feature 3", basic: false, standard: true, premium: true },
    { name: "Feature 4", basic: false, standard: false, premium: true },
    { name: "Feature 5", basic: true, standard: true, premium: false },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        style={{ paddingTop: 20, color: "white", display: "inline-block" }}
      >
        Feature
      </Typography>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        style={{
          color: "#008000cd",
          display: "inline-block",
          marginLeft: "5px",
        }}
      >
        comparison.
      </Typography>
      {isMobile ? (
        <Grid
          container
          spacing={2}
          sx={{ backgroundColor: "primary.background" }}
        >
          <Grid item xs={12}>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6" align="center">
                Features
              </Typography>
              {features.map((feature) => (
                <Box key={feature.name} sx={{ marginBottom: 2 }}>
                  <Typography
                    variant="body1"
                    align="center"
                    sx={{ padding: "8px 0", fontWeight: "bold" }}
                  >
                    {feature.name}
                  </Typography>
                  <Grid container>
                    <Grid item xs={4} align="center">
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        Basic
                      </Typography>
                      {feature.basic ? "✔️" : "❌"}
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        Standard
                      </Typography>
                      {feature.standard ? "✔️" : "❌"}
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                      >
                        Premium
                      </Typography>
                      {feature.premium ? "✔️" : "❌"}
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} sx={{ backgroundColor: "none" }}>
          <Grid item xs={12} sm={2}>
            <Paper sx={{ padding: 2, background: "none" }}>
              <Typography
                variant="h6"
                align="right"
                sx={{ color: "#008000cd" }}
              >
                Monitoring
              </Typography>
              {features.map((feature) => (
                <Typography
                  key={feature.name}
                  variant="body1"
                  align="right"
                  sx={{ padding: "8px 0", color: "white" }}
                >
                  {feature.name}
                </Typography>
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Paper sx={{ padding: 2 }}>
              <Grid container>
                <Grid item xs={3} align="center">
                  <Typography variant="h6">Free</Typography>
                </Grid>
                <Grid item xs={3} align="center">
                  <Typography variant="h6">Solo</Typography>
                </Grid>
                <Grid item xs={3} align="center">
                  <Typography variant="h6">Team</Typography>
                </Grid>
                <Grid item xs={3} align="center">
                  <Typography variant="h6">Enterprise</Typography>
                </Grid>
              </Grid>
              {features.map((feature) => (
                <Grid container key={feature.name} sx={{ padding: "8px 0" }}>
                  <Grid item xs={3} align="center">
                    {feature.basic ? "✔️" : "❌"}
                  </Grid>
                  <Grid item xs={3} align="center">
                    {feature.standard ? "✔️" : "❌"}
                  </Grid>
                  <Grid item xs={3} align="center">
                    {feature.premium ? "✔️" : "❌"}
                  </Grid>
                  <Grid item xs={3} align="center">
                    {feature.enterprise ? "✔️" : "❌"}
                  </Grid>
                </Grid>
              ))}
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default FeatureComparison;
