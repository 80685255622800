import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import {
  IconArrowBack,
  IconEdit,
  IconLock,
  IconPlus,
  IconSettings,
} from "@tabler/icons-react";

export const DownloadButton = ({ text = "Download" }) => {
  return (
    <Button
      variant="outlined"
      href="#outlined-buttons"
      sx={{ color: "#727881", borderColor: "#727881", margin: "3px 0" }}
    >
      {text}
    </Button>
  );
};

export const SoonButton = ({ text = "Soon" }) => {
  return (
    <Button
      disabled={true}
      variant="outlined"
      sx={{
        color: "white",
        borderColor: "white",
        margin: "3px 5px 0 0",
        "&.Mui-disabled": {
          color: (theme) => theme.palette.primary.secondTextColor,
          borderColor: (theme) => theme.palette.primary.secondTextColor,
        },
      }}
    >
      {text}
    </Button>
  );
};

export const AddButton = ({ text = "Add", ...props }) => {
  return (
    <Button
      {...props}
      variant="outlined"
      startIcon={<IconPlus />}
      sx={{
        color: "#727881",
        borderColor: "#727881",
        margin: "3px 0",
        ...props.sx,
      }}
    >
      {text}
    </Button>
  );
};

export const BackButton = ({ text = "Status pages", ...props }) => {
  return (
    <Button
      {...props}
      variant="outlined"
      startIcon={<IconArrowBack />}
      sx={{
        color: "#727881",
        borderColor: "#727881",
        margin: "3px 0",
        ...props.sx,
      }}
    >
      {text}
    </Button>
  );
};

export const EditButton = ({ text = "Status pages", ...props }) => {
  return (
    <Button
      {...props}
      variant="outlined"
      startIcon={<IconEdit />}
      sx={{
        color: "#727881",
        borderColor: "#727881",
        margin: "3px 0 3px 3px",
        ...props.sx,
      }}
    >
      {text}
    </Button>
  );
};

export const CustomButton = ({ text = "Status pages", ...props }) => {
  return (
    <Button
      {...props}
      variant="outlined"
      startIcon={(props && props.icon) || <IconSettings />}
      sx={{
        color: "#e0e0e0", // Beyaza yakın yazı rengi
        backgroundColor: props.active ? "#008000cd" : "#131a25", // Buton rengi
        borderColor: props.active ? "#008000cd" : "#131a25", // Kenar rengi
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Yumuşak gölge
        transition: "background-color 0.3s ease, transform 0.2s ease", // Geçiş animasyonu
        margin: "3px 0 3px 3px",
        "&:hover": {
          backgroundColor: props.active ? "#008000cd" : "#151f2f", // Hover durumu için grimtırak renk
          borderColor: props.active ? "#008000cd" : "#151f2f", // Kenar rengini hoverda da aynı yapalım
        },
        "&:active": {
          backgroundColor: "#008000cd", // Tıklama durumunda yeşil renk
          transform: "scale(0.98)", // Tıklama animasyonu için hafif küçültme
        },
        ...props.sx,
      }}
    >
      {text}
    </Button>
  );
};

export const AvailableOnlyAlert = ({ type = "solo", link = "/pricing" }) => {
  let text = "Available only in Solo, Team and Enterprise.";
  switch (type) {
    case "solo":
      text = (
        <span>
          <span>Available only in</span>
          <span style={{ fontWeight: "bold" }}> Solo, Team</span>
          <span> and</span>
          <span style={{ fontWeight: "bold" }}> Enterprise.</span>
        </span>
      );
      break;
    case "team":
      text = (
        <span>
          <span>Available only in</span>
          <span style={{ fontWeight: "bold" }}> Team</span>
          <span> and</span>
          <span style={{ fontWeight: "bold" }}> Enterprise.</span>
        </span>
      );
      break;
    default:
      break;
  }
  return (
    <Typography
      sx={{
        p: 0.6,
        border: "1px dashed grey",
        borderRadius: "3px",
        fontSize: "12px",
        color: "#e6eaeb",
        display: "inline-flex",
        height: 30,
        marginLeft: "auto",
        marginRight: "5px",
      }}
    >
      <IconLock color="#dcba13" stroke={1} size={18} />
      <span style={{ marginLeft: 5 }}>{text}</span>
      <a
        href={link}
        target="_blank"
        rel="noreferrer"
        style={{
          color: "#727881",
          textDecoration: "underline",
          marginLeft: 2,
          fontWeight: "bold",
        }}
      >
        {" "}
        Upgrade now!
      </a>
    </Typography>
  );
};
