import React, { Component } from "react";
import { connect } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import { SubscriptionFailed } from "../helper/Backgrounds";
import { Link } from "react-router-dom";

class SubscriptionFailed_ extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }
  componentDidMount() {
    document.title = "Failed Payment | Statusnap";
  }

  render() {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          height: window.innerHeight / 1.3,
        }}
      >
        <SubscriptionFailed />
        <Typography variant="h5" gutterBottom style={{ color: "white" }}>
          Payment Failed!
        </Typography>
        <Typography variant="body1" gutterBottom style={{ color: "white" }}>
          Your payment was unsuccessful. Please try again or contact our support
          team.
        </Typography>
        <Typography variant="body2" gutterBottom style={{ color: "white" }}>
          You can reach our support team at{" "}
          <a href="mailto:info@statusnap.com">info@statusnap.com</a>.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/subscription"
          style={{ color: "white", marginTop: 30 }}
        >
          Retry Payment
        </Button>
      </Box>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
}))(SubscriptionFailed_);
