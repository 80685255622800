/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import DashboardCard from "../components/dashboard/dashboardCard";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Page from "../components/Page";
import { Formik } from "formik";
import DropdownVariables from "../helper/timeZone";
import { post } from "../util/api";
import { updateProfile } from "../actions/access.actions";

const ChangeAccountValidation = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email.")
    .required("Email is required!"),
  name: Yup.string().required("Name is required!"),
  surname: Yup.string().required("Surname is required!"),
});

const ChangePasswordValidation = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      "Password must be at least 8 characters long and contain one uppercase, one lowercase, one number, and one special character."
    )
    .required("Password is required!"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords do not match!")
    .required("Password confirmation is required."),
});

class Account extends Component {
  constructor(props) {
    console.log("props ", props);
    super(props);
    this.state = {
      loading: true,
      servers_: [],
      days: 30,
      name: props.user && props.user.name,
      surname: props.user && props.user.surname,
      utc: (props.user && props.user.extra && props.user.extra.utc) || "UTC",
      email: props.user && props.user.email,
      phone: props.user && props.user.phone,
      company: (props.user && props.user.company) || "",
      extra: (props.user && props.user.extra) || {},
    };
  }

  componentDidMount() {
    document.title = "Account | Statusnap";
  }

  render() {
    let { loading, name, surname, utc, email, phone, company, extra } =
      this.state;
    if (!loading) return <Page.Loader key="loader" />;
    return (
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row", padding: 0 }}
      >
        <Box flex={{ xs: "1 1 auto", md: "0 0 100%" }}>
          <Box
            display="flex"
            flexDirection={{ xs: "column", md: "row", padding: 0 }}
          >
            <Box flex={{ xs: "1 1 auto", md: "0 0 50%" }}>
              <DashboardCard title="Account details">
                <Formik
                  initialValues={{
                    name,
                    surname,
                    utc,
                    email,
                    phone,
                    company,
                  }}
                  onSubmit={(values) => {
                    let { company, email, name, phone, surname, utc } = values;
                    this.props.updateUser(values);
                    post("/auth/updateInfo", {
                      name: name,
                      phone: phone,
                      company: company,
                      email: email,
                      surname: surname,
                      extra: { ...extra, utc },
                    });
                  }}
                  validationSchema={ChangeAccountValidation}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 1 }}
                    >
                      <TextField
                        margin="normal"
                        className="custom-textfield"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        type="name"
                        color="secondary"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: {
                            color: "white",
                            caretColor: "white",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#c9c9c9",
                            },
                            "&:hover fieldset": {
                              borderColor: "#15a32a",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#c9c9c9",
                          },
                          "& .MuiFormHelperText-root": {
                            color: "#c9c9c9",
                          },
                        }}
                      />
                      <TextField
                        margin="normal"
                        className="custom-textfield"
                        required
                        fullWidth
                        id="surname"
                        label="Surname"
                        name="surname"
                        autoComplete="surname"
                        color="secondary"
                        type="surname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.surname}
                        error={touched.surname && Boolean(errors.surname)}
                        helperText={touched.surname && errors.surname}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: {
                            color: "white",
                            caretColor: "white",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#c9c9c9",
                            },
                            "&:hover fieldset": {
                              borderColor: "#15a32a",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#c9c9c9",
                          },
                          "& .MuiFormHelperText-root": {
                            color: "#c9c9c9",
                          },
                        }}
                      />
                      <FormControl
                        sx={{
                          "@media screen and (max-width: 600px)": {
                            width: "100%",
                          },
                          height: "56px",
                          width: "100%",
                          color: "white",
                          marginTop: "5px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#c9c9c9",
                            },
                            "&:hover fieldset": {
                              borderColor: "#15a32a",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#c9c9c9",
                          },
                          "& .MuiFormHelperText-root": {
                            color: "#c9c9c9",
                          },
                        }}
                      >
                        <InputLabel
                          id="dropdown-label"
                          sx={{
                            color: (theme) =>
                              theme.palette.primary.secondTextColor,
                            "&.Mui-focused": { color: "white" },
                          }}
                        >
                          UTC
                        </InputLabel>
                        <Select
                          sx={{
                            caretColor: "white",
                            color: "white",
                            height: "56px",
                            "&:hover": {
                              color: "#c9c9c9",
                            },
                            "& .MuiOutlinedInput-root": {
                              color: (theme) =>
                                theme.palette.primary.secondTextColor,
                            },
                            "& .MuiInputAdornment-root": {
                              color: (theme) =>
                                theme.palette.primary.secondTextColor,
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: (theme) =>
                                theme.palette.primary.secondTextColor,
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: (theme) =>
                                theme.palette.primary.secondTextColor,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor: (theme) =>
                                theme.palette.primary.secondTextColor,
                            },
                          }}
                          labelId="dropdown-label"
                          value={values.utc}
                          id="utc"
                          name="utc"
                          label="UTC"
                          onChange={handleChange}
                        >
                          {_.map(DropdownVariables, (e, i) => {
                            return (
                              <MenuItem
                                sx={{ color: "white" }}
                                value={e.value}
                                key={"dropdown-TZ-" + i}
                              >
                                {e.text}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <TextField
                        margin="normal"
                        className="custom-textfield"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        color="secondary"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: {
                            color: "white",
                            caretColor: "white",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#c9c9c9",
                            },
                            "&:hover fieldset": {
                              borderColor: "#15a32a",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#c9c9c9",
                          },
                          "& .MuiFormHelperText-root": {
                            color: "#c9c9c9",
                          },
                        }}
                      />
                      <TextField
                        margin="normal"
                        className="custom-textfield"
                        fullWidth
                        placeholder="+90"
                        id="phone"
                        label="Phone"
                        name="phone"
                        autoComplete="phone"
                        color="secondary"
                        type="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        error={touched.phone && Boolean(errors.phone)}
                        helperText={touched.phone && errors.phone}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: {
                            color: "white",
                            caretColor: "white",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#c9c9c9",
                            },
                            "&:hover fieldset": {
                              borderColor: "#15a32a",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#c9c9c9",
                          },
                          "& .MuiFormHelperText-root": {
                            color: "#c9c9c9",
                          },
                        }}
                      />

                      <TextField
                        margin="normal"
                        className="custom-textfield"
                        fullWidth
                        id="company"
                        label="Company"
                        name="company"
                        autoComplete="company"
                        color="secondary"
                        type="company"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.company}
                        error={touched.company && Boolean(errors.company)}
                        helperText={touched.company && errors.company}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: {
                            color: "white",
                            caretColor: "white",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#c9c9c9",
                            },
                            "&:hover fieldset": {
                              borderColor: "#15a32a",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#c9c9c9",
                          },
                          "& .MuiFormHelperText-root": {
                            color: "#c9c9c9",
                          },
                        }}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        color="secondary"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Save changes
                      </Button>
                    </Box>
                  )}
                </Formik>
              </DashboardCard>
            </Box>
            <Box
              flex={{ xs: "1 1 auto", md: "0 0 50%" }}
              pl={{ xs: 0, md: 3 }}
              pt={{ xs: 3, md: 0 }}
            >
              <DashboardCard title="Change password">
                <Formik
                  initialValues={{
                    password: "",
                    passwordConfirmation: "",
                    currentPassword: "",
                  }}
                  onSubmit={(values) => {
                    let { currentPassword, password, passwordConfirmation } =
                      values;

                    console.log(
                      "password ",
                      password,
                      passwordConfirmation,
                      password == passwordConfirmation
                    );
                    if (password == passwordConfirmation) {
                      this.props.updateUser(values);
                      post("/auth/updatePassword", {
                        newpassword: password,
                      });
                    }
                  }}
                  validationSchema={ChangePasswordValidation}
                  //onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 1 }}
                    >
                      <TextField
                        margin="normal"
                        className="custom-textfield"
                        fullWidth
                        id="password"
                        label="Password"
                        name="password"
                        autoComplete="password"
                        color="secondary"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: {
                            color: "white",
                            caretColor: "white",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#c9c9c9",
                            },
                            "&:hover fieldset": {
                              borderColor: "#15a32a",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#c9c9c9",
                          },
                          "& .MuiFormHelperText-root": {
                            color: "#c9c9c9",
                          },
                        }}
                      />

                      <TextField
                        margin="normal"
                        className="custom-textfield"
                        fullWidth
                        id="passwordConfirmation"
                        label="Password Confirmation"
                        name="passwordConfirmation"
                        autoComplete="password"
                        color="secondary"
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.passwordConfirmation}
                        error={
                          touched.passwordConfirmation &&
                          Boolean(errors.passwordConfirmation)
                        }
                        helperText={
                          touched.passwordConfirmation &&
                          errors.passwordConfirmation
                        }
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          style: {
                            color: "white",
                            caretColor: "white",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#c9c9c9",
                            },
                            "&:hover fieldset": {
                              borderColor: "#15a32a",
                            },
                          },
                          "& .MuiInputLabel-root": {
                            color: "#c9c9c9",
                          },
                          "& .MuiFormHelperText-root": {
                            color: "#c9c9c9",
                          },
                        }}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        color="secondary"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Change Password
                      </Button>
                    </Box>
                  )}
                </Formik>
              </DashboardCard>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default connect(
  (state) => ({
    user: state.access.user.loggedUserObj,
    product: state.product.undefined,
  }),
  (dispatch) => ({ updateUser: (user) => dispatch(updateProfile(user)) })
)(Account);
