import { put, call, takeLatest, select } from "redux-saga/effects";
import * as ActionTypes from "../constants/actionTypes";
import { get, post } from "../util/api";
import lGet from "lodash/get";
import { push } from "connected-react-router";

const getLocation = (state) => state.router.location;

function* login(action) {
  const { username, password } = action;
  try {
    const response = yield call(post, "/auth/login", { username, password });
    if (!response.data || !response.data.success)
      throw new Error("Could't login...");
    //const inOneWeek = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 7);
    //Cookies.set(YTECH_COOKIE_NAME, response.data, { expires: inOneWeek });
    const location = yield select(getLocation);
    let lastPath =
      (location &&
        location.state &&
        location.state.from &&
        location.state.from.pathname) ||
      "/";
    if (/^\/(login|register)$/.test(lastPath)) lastPath = "/";

    yield put({ type: ActionTypes.LOGIN_SUCCEEDED, user: response.data.data });
    yield put(push(lastPath));
  } catch (error) {
    if (lGet(error.response, "data")) {
      yield put({ type: ActionTypes.LOGIN_FAILED, error: error.response.data });
    } else {
      yield put({ type: ActionTypes.LOGIN_FAILED, error });
    }
  }
}

export function* watchLogin() {
  yield takeLatest(ActionTypes.LOGIN_REQUESTED, login);
}

function* register(action) {
  const { email, password } = action;
  try {
    const response = yield call(post, "/auth/register", { email, password });
    if (!response.data || !response.data.success)
      throw new Error("Could't register...");
    const location = yield select(getLocation);
    let lastPath =
      (location &&
        location.state &&
        location.state.from &&
        location.state.from.pathname) ||
      "/";
    if (/^\/(login|register)$/.test(lastPath)) lastPath = "/";
    yield put({
      type: ActionTypes.REGISTER_SUCCEEDED,
      user: response.data.data,
    });
    yield put(push(lastPath));
    yield put({ type: ActionTypes.PROFILE_REQUESTED });
  } catch (error) {
    if (lGet(error.response, "data")) {
      yield put({
        type: ActionTypes.REGISTER_FAILED,
        error: error.response.data,
      });
    } else {
      yield put({ type: ActionTypes.REGISTER_FAILED, error });
    }
  }
}

export function* watchRegister() {
  yield takeLatest(ActionTypes.REGISTER_REQUESTED, register);
}

function* logout() {
  try {
    yield call(get, "/auth/logout");
    yield put({ type: ActionTypes.LOGOUT_SUCCEEDED });
  } catch (error) {
    if (lGet(error.response, "data")) {
      yield put({
        type: ActionTypes.LOGOUT_FAILED,
        error: error.response.data,
      });
    } else {
      yield put({ type: ActionTypes.LOGOUT_FAILED, error: error.response });
    }
  }
}

export function* watchLogout() {
  yield takeLatest(ActionTypes.LOGOUT_REQUESTED, logout);
}

function* getProfile() {
  try {
    const response = yield call(get, "/auth/user");
    if (!response.success || !response.data)
      throw new Error("Session is not valid.");
    yield put({ type: ActionTypes.PROFILE_SUCCEEDED, user: response.data });
  } catch (error) {
    yield put({ type: ActionTypes.PROFILE_FAILED, error: error.response });
  }
}

export function* watchGetProfile() {
  yield takeLatest(ActionTypes.PROFILE_REQUESTED, getProfile);
}
