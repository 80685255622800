import axios from "axios";
import env from "react-dotenv";

const API_ADDRESS =
  (env && env.APP_API_URL) || "https://console.statusnap.com/api";

export function get(path, params) {
  const url = `${API_ADDRESS}${path}`;

  return axios({
    method: "get",
    url,
    params,
    withCredentials: true,
  }).then((resp) => resp.data);
}

export function post(path, data, params) {
  const url = `${API_ADDRESS}${path}`;
  return axios({
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    url,
    data: "__json__=" + encodeURIComponent(JSON.stringify(data)),
    params,
    withCredentials: true,
  });
}
