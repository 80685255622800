import { Box, Typography } from "@mui/material";

export const ComingSoon = () => {
  return (
    <Box
      style={{ padding: 0, zIndex: "-99999" }}
      sx={{
        left: 0,
        top: 0,
        width: window.innerWidth,
        height: window.innerHeight,
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffff38",
      }}
    >
      <Box
        className="soon-background"
        sx={{
          left: 0,
          top: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }}
      />
    </Box>
  );
};

export const SubscriptionFailed = () => {
  return (
    <Box
      style={{ padding: 0, zIndex: "-99999" }}
      sx={{
        left: 0,
        top: 0,
        width: window.innerWidth,
        height: window.innerHeight,
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffff38",
      }}
    >
      <Box
        className="failed-background"
        sx={{
          left: 0,
          top: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }}
      />
    </Box>
  );
};

export const SubscriptionSuccess = () => {
  return (
    <Box
      style={{ padding: 0, zIndex: "-99999" }}
      sx={{
        left: 0,
        top: 0,
        width: window.innerWidth,
        height: window.innerHeight,
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ffffff38",
      }}
    >
      <Box
        className="success-background"
        sx={{
          left: 0,
          top: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        }}
      />
    </Box>
  );
};
