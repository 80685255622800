import React, { Component } from "react";
import { connect } from "react-redux";
import {
  AddButton,
  AvailableOnlyAlert,
  DownloadButton,
} from "../helper/Buttons";

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    document.title = "Team | Statusnap";
  }

  render() {
    return (
      <>
        Team Page Active
        <AvailableOnlyAlert />
      </>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
}))(Team);
