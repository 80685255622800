import React, { Component } from "react";
import { connect } from "react-redux";
import { Container } from "@mui/material";
import FeatureComparison from "../components/comparison/FeatureComparison";

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    document.title = "Subscription | Statusnap";
  }

  render() {
    return (
      <Container style={{ padding: 0 }}>
        {/* <FeatureComparison /> */}
      </Container>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
}))(Subscription);
