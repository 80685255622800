import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { keyframes } from "@emotion/react";
import { useHistory } from "react-router-dom"; // React Router kullanarak

// Yanıp sönen animasyon
const pulse = keyframes`
  0% {
    opacity: 1;
    box-shadow: 0 0 40px #ff0000;
  }
  50% {
    opacity: 0;
    box-shadow: none;
  }
  100% {
    opacity: 1;
    box-shadow: 0 0 40px #ff0000;
  }
`;

const Page404 = () => {
  React.useEffect(() => {
    document.title = "Page not found | Statusnap";
  }, []);
  const history = useHistory(); // React Router'ın useHistory hook'u

  const handleHomeClick = () => {
    history.push("/"); // Ana sayfaya yönlendirme
  };

  return (
    <Box
      sx={{
        backgroundColor: "#151f2f",
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        textAlign: "center",
        padding: 2,
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "6rem",
          mb: 2,
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          color: "#d7d7d7",
        }}
      >
        4
        <Box
          component="span"
          sx={{
            ml: 1,
            mr: 1,
            display: "inline-block",
            width: "5rem",
            height: "5rem",
            borderRadius: "50%",
            backgroundColor: "#ff0000",
            animation: `${pulse} 1s infinite`,
          }}
        />
        4
      </Typography>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Page Not Found!
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, maxWidth: "600px" }}>
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable. Please check the URL or return
        to the home page.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleHomeClick}
        sx={{
          color: "white",
          backgroundColor: "#ff0000c7",
          ":hover": {
            backgroundColor: "#ff0000f7",
          },
        }}
      >
        Go to Home Page
      </Button>
    </Box>
  );
};

export default Page404;
