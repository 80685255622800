import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  styled,
  LinearProgress,
  Grid,
  Popover,
  Paper,
} from "@mui/material";
import { getDaysValue } from "../../helper/generalControl";
import { Link } from "react-router-dom";

const PopoverContent = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  color: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[800]}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[10],
  padding: theme.spacing(2),
  width: "300px",
}));

const StatusBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
}));

const GreenText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const WhiteText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginBottom: theme.spacing(0.5),
}));

const GrayText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  marginBottom: theme.spacing(0.5),
}));

const TriggerButton = styled("span")(({ theme }) => ({
  color: theme.palette.common.white,
  border: "none",
  padding: theme.spacing(1, 0),
  cursor: "pointer",
  fontSize: "14px",
}));

const PopupContent = ({
  monthName,
  day,
  apdex,
  downStatus,
  tolarence,
  noDownTime,
  timeDownTime,
  notMonitored,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  let selectedDays = "colour-green";
  let timeUp = noDownTime;
  if (downStatus == null) {
    timeUp = timeDownTime;
    selectedDays = "colour-gray";
  }
  if (downStatus < 15 && downStatus !== 0 && downStatus != null) {
    timeUp = timeDownTime;
    selectedDays = "colour-orange";
  }
  if (downStatus >= 15) {
    timeUp = timeDownTime;
    selectedDays = "colour-red";
  }

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div
        className={`one-bar ${selectedDays}`}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      ></div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {monthName ? (
          <Box
            sx={{
              p: 2,
              textAlign: "center",
              color: "gray",
              background: "#131a25",
            }}
          >
            <Typography sx={{ color: "white" }}>
              {day} {monthName}
            </Typography>
            <div>
              <Typography className={"text-" + selectedDays}>
                {downStatus == 0 ? "" : downStatus}
                {timeUp}
                {apdex && (
                  <Typography
                    component="span"
                    sx={{ fontSize: "12px", color: "#c9c9c9" }}
                  >
                    {" "}
                    - {parseFloat(apdex).toFixed(apdex == 1 ? 1 : 2)} Apdex (
                    {tolarence}s)
                  </Typography>
                )}
              </Typography>
            </div>
          </Box>
        ) : (
          <Box
            sx={{
              p: 2,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              color: "white",
              background: "#131a25",
            }}
          >
            <Typography>{notMonitored}</Typography>
          </Box>
        )}
      </Popover>
    </div>
  );
};

const PopupWrapper = ({ status, days: days_, noDownTime, timeDownTime }) => {
  var popupContent = [];
  let counter = 0;

  let notMonitored = "izlenemedi.";

  const sortedData = Object.entries(status)
    .map(([key, value]) => {
      const [day, month, year] = key.split("-");
      return { key, date: new Date(`${year}-${month}-${day}`), value };
    })
    .sort((a, b) => a.date - b.date)
    .reduce((acc, { key, value }) => {
      acc[key] = value;
      return acc;
    }, {});

  status = sortedData;
  for (let i = Object.keys(status).length - 1; i >= 0; i--) {
    let day_ = Object.keys(status)[i];
    let [day, month, year] = day_.split("-");
    let formattedDateStr = `${month}-${day}-${year}`;
    let monthName = new Date(formattedDateStr).toLocaleDateString("en", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    if (monthName) {
      if (counter <= days_) {
        const downStatus = status[day_].down;
        const apdex = status[day_].apdex;
        const tolarence = status[day_].apdex_tolerance;

        popupContent.unshift(
          <PopupContent
            key={counter}
            day={null}
            apdex={apdex}
            tolarence={tolarence || "1.0"}
            monthName={monthName}
            downStatus={downStatus}
            noDownTime={noDownTime}
            timeDownTime={timeDownTime}
            notMonitored={notMonitored}
          />
        );
      }

      counter++;
    }
  }
  if (popupContent.length < days_) {
    let len = popupContent.length;
    for (let index = len; index < days_; index++) {
      popupContent.unshift(
        <PopupContent
          key={index}
          day={null}
          apdex={null}
          tolarence={null}
          monthName={null}
          downStatus={null}
          noDownTime={null}
          timeDownTime={null}
          notMonitored={"No data"}
        />
      );
    }
  }
  return <div className="lastdays">{popupContent}</div>;
};

const StatusesPreview = ({ data, servers_, margin, solo }) => {
  useEffect(() => {
    if (window) {
      window.addEventListener("resize", handleResize, true);
      handleResize();
    }

    return () => {
      if (window) window.removeEventListener("resize", handleResize, true);
    };
  }, []);

  const handleResize = () => {
    var day = 90;
    if (window && window.innerWidth) day = window.innerWidth;
    let res = getDaysValue(day);
    setDay(res);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [day, setDay] = useState(30);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let { server_settings, id: id_ } = data;
  let { link, status_code, status_text, certificate, domain } = server_settings;

  return (
    <Box sx={{ marginRight: margin }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ marginRight: "auto" }}>
          <TriggerButton aria-describedby={id} onClick={handleClick}>
            <Typography
              color={"#15a32a"}
              sx={{
                display: "inline-block",
                marginRight: "2px",
                marginLeft: "2px",
              }}
            >
              {" "}
              https://{" "}
            </Typography>
          </TriggerButton>
          <Typography sx={{ display: "inline-block", color: "white" }}>
            {link}
          </Typography>
        </Box>
        {!solo ? (
          <Box>
            <Typography
              style={{ textDecoration: "underline", color: "white" }}
              mr={2}
              component={Link}
              to={`/monitor/${id_}`}
            >
              Monitoring
            </Typography>
            <Typography
              style={{ textDecoration: "underline", color: "white" }}
              component={Link}
              to={`/monitors/${id_}`}
            >
              Edit
            </Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <PopoverContent
          sx={{ borderRadius: "0", background: "#151f2f", border: "none" }}
        >
          <GreenText variant="body2">
            {status_code} {status_text} https://{link}
          </GreenText>

          <WhiteText variant="body2">
            {certificate && certificate.subject && domain
              ? "Valid SSL certificate"
              : "Unvalid SSL certificate"}
          </WhiteText>
          {domain && domain.tested ? (
            <GrayText variant="body2">
              Tested On{" "}
              {new Date(domain.domain_tested).toLocaleDateString("en", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </GrayText>
          ) : (
            <></>
          )}

          <WhiteText variant="body2" fontWeight="bold">
            SUBJECT: {certificate.subject}
          </WhiteText>
          <GrayText variant="body2">*.{certificate.subject}</GrayText>
          <WhiteText variant="body2">
            VALIDITY:{" "}
            {new Date(certificate.valid_from).toLocaleDateString("en", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}{" "}
            -{" "}
            {new Date(certificate.valid_to).toLocaleDateString("en", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </WhiteText>
          <GrayText variant="body2">
            ISSUER: {certificate.issuer.CN} ({certificate.issuer.O})
          </GrayText>

          <GreenText variant="body2">
            {domain && domain.domain_expire ? (
              <>
                {"Domain name expires in " +
                  `${Math.ceil(
                    (new Date(domain.domain_expire) -
                      new Date(domain.domain_update)) /
                      (1000 * 60 * 60 * 24)
                  )}`}
              </>
            ) : (
              "Domain expiration time not found."
            )}
          </GreenText>
          <GrayText variant="body2">
            {domain && domain.domain_update ? (
              <>
                {new Date(domain.domain_update).toLocaleDateString("en", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </>
            ) : (
              <>Domain updated time not found.</>
            )}
          </GrayText>

          <Box display="flex" justifyContent="space-between" mt={2}>
            <WhiteText variant="body2" fontWeight="bold">
              EXPIRES:{" "}
              {new Date(domain && domain.domain_expire).toLocaleDateString(
                "en",
                {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                }
              )}{" "}
              (RDAP)
            </WhiteText>
          </Box>
        </PopoverContent>
      </Popover>
      {servers_ && servers_.status ? (
        <PopupWrapper
          status={servers_.status}
          days={day}
          noDownTime={"No downtime"}
          timeDownTime={" m downtime"}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default StatusesPreview;
