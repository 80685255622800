import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./components/Header";
import {
  RegisterPage,
  LoginPage,
  ForgotPage,
  CheckMailPage,
  ResetPasswordPage,
} from "./pages/Auth";
import Page from "./components/Page";
import { load_product } from "./actions/product.actions";
import Home from "./pages/Home";
import Account from "./pages/Account";

import Page404 from "./pages/404";
import Team from "./pages/Team";
import TeamAlert from "./pages/TeamAlert";
import ClusterAlert from "./pages/ClusterAlert";

import Integrations from "./pages/Integrations";
import Incidents from "./pages/Incidents";
import Status from "./pages/Status";
import Subscription from "./pages/Subscription";
import SubscriptionFailed from "./pages/SubscriptionFailed";
import SubscriptionSuccessfull from "./pages/SubscriptionSuccessfull";
import Pricing from "./pages/Pricing";
import StatusDetail from "./pages/StatusDetail";
import Monitoring from "./pages/Monitoring";
import Checkout from "./pages/Checkout";
import Verification from "./components/Verification";

const Layout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <>
        <Header component={<Component {...matchProps} />} title={rest.title} />
      </>
    )}
  />
);

const loader = <Page.Loader key="loader" />;

function LoadProduct({ dispatch }) {
  React.useEffect(() => {
    dispatch(load_product());
  }, [dispatch]);
  return <Page.Loader key="loader" />;
}
class App extends React.PureComponent {
  render() {
    const { access, product } = this.props;
    const user = access.user;
    const isLogin = user && user.isAuthenticated;

    const teamMembers =
      product &&
      product.undefined &&
      product.undefined.subscription &&
      product.undefined.subscription.teamMembers;

    const isVerified =
      user && user.loggedUserObj && !user.loggedUserObj.verified;

    if (access.loading === "profile" || product.loading) return loader;

    return (
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/register" component={RegisterPage} />
        <Route exact path="/forgot" component={ForgotPage} />
        <Route path="/check/mail" component={CheckMailPage} />
        <Route path="/verify/reset/:token" component={ResetPasswordPage} />
        {!isLogin && (
          <Redirect
            to={{ pathname: "/login", state: { from: this.props.location } }}
          />
        )}
        {!this.props.product.undefined && (
          <Route component={() => <LoadProduct {...this.props} />} />
        )}
        {isVerified && <Layout component={Verification} />}
        <Layout path="/" exact component={Home} title="Dashboard" />
        <Layout path="/account" exact component={Account} title="Account" />

        <Layout
          path="/integrations"
          exact
          component={Integrations}
          title="Integrations"
        />
        <Route path="/checkout" exact component={Checkout} title="Checkout" />
        <Layout path="/monitors" exact component={Status} title="Monitors" />
        <Layout
          path="/monitors/:id"
          exact
          component={StatusDetail}
          title="Edit Monitor"
        />
        <Layout
          path="/monitor/:id"
          exact
          component={Monitoring}
          title="Monitoring Detail"
        />

        <Layout
          path="/incidents"
          exact
          component={Incidents}
          title="Incidents"
        />
        <Layout
          path="/cluster"
          exact
          component={ClusterAlert}
          title="Cluster"
        />
        <Layout
          path="/subscription"
          exact
          component={Subscription}
          title="Subscription"
        />
        <Route
          path="/subscription-failed"
          exact
          component={SubscriptionFailed}
        />
        <Route
          path="/subscription-success"
          exact
          component={SubscriptionSuccessfull}
        />

        <Route path="/pricing" exact component={Pricing} />

        {teamMembers ? (
          <Layout path="/team" exact component={Team} title="Team Members" />
        ) : (
          <Layout
            path="/team"
            exact
            component={TeamAlert}
            title="Team Members"
          />
        )}
        <Route component={Page404} />
      </Switch>
    );
  }
}

const stateToProps = (state) => ({
  access: state.access,
  product: state.product,
  location: state.router.location,
});

export default connect(stateToProps)(App);
