import React, { useState } from "react";
import PricingBox from "./PricingBox";
import OfferList from "./OfferList";
import { Box } from "@mui/material";

const ParentComponent = () => {
  const [isMonthly, setIsMonthly] = useState(true);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: {
          xs: "1fr",
          md: "repeat(2, 1fr)",
          lg: "repeat(4, 1fr)",
        },
        gap: 4,
        marginBottom: 15,
      }}
    >
      <PricingBox
        packageName="Free"
        popular={false}
        price={isMonthly ? "0" : "0"}
        oldPrice={"0"}
        duration={isMonthly ? "mo" : "mo"}
        isMonthly={isMonthly}
        monitors={"50"}
        subtitle="Ideal for personal projects. No credit card required!"
      >
        <OfferList text="5 min. monitoring interval" status="active" />
        <OfferList text="HTTP, port & ping monitor" status="active" />
        <OfferList text="SSL & Domain exp. monitor" status="active" />
        <OfferList text="Only 3 different country" status="active" />
        <OfferList text="Basic status page" status="active" />
        <OfferList text="Custom domain" status="inactive" />
        <OfferList text="Notification unavailable" status="inactive" />
        <OfferList text="Mobile app unavailable" status="inactive" />
      </PricingBox>
      <PricingBox
        packageName="Solo"
        popular={false}
        price={isMonthly ? "6" : "5"}
        oldPrice={"6"}
        duration={isMonthly ? "mo" : "mo"}
        isMonthly={isMonthly}
        monitors={"10, 30 or 50"}
        subtitle="Perfect for solo entrepreneurs and enthusiasts."
      >
        <OfferList text="1 min. monitoring interval" status="active" />
        <OfferList text="HTTP, port & ping monitor" status="active" />
        <OfferList text="SSL & Domain exp. monitor" status="active" />
        <OfferList text="Only 5 different country" status="active" />
        <OfferList text="Basic status page" status="active" />
        <OfferList text="Custom domain" status="active" />
        <OfferList text="Limited notifications available" status="active" />
        <OfferList text="Mobile app unavailable" status="inactive" />
      </PricingBox>
      <PricingBox
        packageName="Team"
        popular={true}
        price={isMonthly ? "29" : "22"}
        oldPrice={"29"}
        v
        monitors={"100"}
        duration={isMonthly ? "mo" : "mo"}
        isMonthly={isMonthly}
        subtitle="Ideal for small teams looking to collaborate effectively."
      >
        <OfferList text="15 sec. monitoring interval" status="active" />
        <OfferList text="HTTP, port & ping monitor" status="active" />
        <OfferList text="SSL & Domain exp. monitor" status="active" />
        <OfferList text="All 10 different country" status="active" />
        <OfferList text="Full-featured status pages" status="active" />
        <OfferList text="Custom domain" status="active" />
        <OfferList text="All notifications available" status="active" />
        <OfferList text="Mobile app available" status="active" />
      </PricingBox>
      <PricingBox
        packageName="Enterprise"
        popular={false}
        price={isMonthly ? "270" : "205"}
        oldPrice={"270"}
        monitors={"250, 500, 750 or 1,000"}
        duration={isMonthly ? "mo" : "mo"}
        isMonthly={isMonthly}
        subtitle="For those who professional need more."
      >
        <OfferList text="15 sec. monitoring interval" status="active" />
        <OfferList text="HTTP, port & ping monitor" status="active" />
        <OfferList text="SSL & Domain exp. monitor" status="active" />
        <OfferList text="All 10 different country" status="active" />
        <OfferList text="Full-featured status pages" status="active" />
        <OfferList text="Custom domain" status="active" />
        <OfferList text="All notifications available" status="active" />
        <OfferList text="Mobile app available" status="active" />
      </PricingBox>
    </Box>
  );
};

export default ParentComponent;
