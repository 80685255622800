import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Menu,
  Button,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  IconInvoice,
  IconListCheck,
  IconLogout2,
  IconMail,
  IconUser,
} from "@tabler/icons-react";

const Profile = (prop) => {
  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="show 11 new notifications"
        color="inherit"
        aria-controls="msgs-menu"
        aria-haspopup="true"
        sx={{
          ...(typeof anchorEl2 === "object" && {
            color: "primary.main",
          }),
        }}
        onClick={handleClick2}
      >
        <Avatar
          // src="/images/profile/user-1.jpg"
          alt="image"
          sx={{
            width: 35,
            height: 35,
            backgroundColor: (theme) => theme.palette.primary.backgroundHeader,
          }}
        />
      </IconButton>
      {/* ------------------------------------------- */}
      {/* Message Dropdown */}
      {/* ------------------------------------------- */}
      <Menu
        id="msgs-menu"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        sx={{
          "& .MuiMenu-paper": {
            width: "200px",
          },
        }}
      >
        <MenuItem
          component={Link}
          to="/account"
          sx={{
            backgroundColor: "#131a25",
            color: (theme) => theme.palette.primary.textColor,
          }}
        >
          <ListItemIcon>
            <IconUser width={20} color="#008000cd" />
          </ListItemIcon>
          <ListItemText>Account</ListItemText>
        </MenuItem>
        {/* <MenuItem>
          <ListItemIcon>
            <IconMail width={20} />
          </ListItemIcon>
          <ListItemText>My Account</ListItemText>
        </MenuItem> */}
        <MenuItem
          component={Link}
          to="/subscription"
          sx={{
            backgroundColor: "#131a25",
            color: (theme) => theme.palette.primary.textColor,
          }}
        >
          <ListItemIcon>
            <IconListCheck width={20} color="#008000cd" />
          </ListItemIcon>
          <ListItemText>Subscription</ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          to="/invoices"
          sx={{
            backgroundColor: "#131a25",
            color: (theme) => theme.palette.primary.textColor,
          }}
        >
          <ListItemIcon>
            <IconInvoice width={20} color="#008000cd" />
          </ListItemIcon>
          <ListItemText>Invoices</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => prop.logoutUser()}
          sx={{
            backgroundColor: "#f100007a",
            "&:hover": { backgroundColor: "#f100007a" },
            color: (theme) => theme.palette.primary.textColor,
          }}
        >
          <ListItemIcon>
            <IconLogout2 width={20} color="red" />
          </ListItemIcon>
          <ListItemText>Log out</ListItemText>
        </MenuItem>
        {/* <Box
          mt={1}
          py={1}
          px={2}
          sx={{
            backgroundColor: "#131a25",
          }}
        >
          <Button
            to="/authentication/login"
            variant="outlined"
            color="primary"
            component={Link}
            fullWidth
            sx={{ color: (theme) => theme.palette.primary.textColor }}
          >
            Logout
          </Button>
        </Box> */}
      </Menu>
    </Box>
  );
};

export default Profile;
