import React from "react";
import { Card, CardContent, Typography, Stack, Box } from "@mui/material";

const DashboardCard = ({
  title,
  subtitle,
  children,
  action,
  footer,
  cardheading,
  headtitle,
  headsubtitle,
  middlecontent,
  actionColor,
}) => {
  return (
    <Card
      sx={{ padding: 0, backgroundColor: "#131a25" }}
      elevation={9}
      variant={undefined}
    >
      {cardheading ? (
        <CardContent>
          <Typography variant="h5" sx={{ color: "#fff" }}>
            {headtitle}
          </Typography>
          <Typography variant="subtitle2" sx={{ color: "#fff" }}>
            {headsubtitle}
          </Typography>
        </CardContent>
      ) : (
        <CardContent sx={{ p: "30px" }}>
          {title ? (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems={"center"}
              mb={3}
              sx={{ color: actionColor || "#fff" }}
            >
              <Box>
                {title ? (
                  <Typography variant="h5" sx={{ color: "#fff" }}>
                    {title}
                  </Typography>
                ) : (
                  ""
                )}

                {subtitle ? (
                  <Typography variant="subtitle2" sx={{ color: "#fff" }}>
                    {subtitle}
                  </Typography>
                ) : (
                  ""
                )}
              </Box>
              {action}
            </Stack>
          ) : null}

          {children}
        </CardContent>
      )}

      {middlecontent}
      {footer}
    </Card>
  );
};

export default DashboardCard;
