import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import configureStore, { history } from "./configureStore";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import Error from "./components/Error";
import "./index.css";
import App from "./App";
import theme from "./theme";
import './scss/app.scss'

const store = configureStore();

class Logic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.warn(error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    const { error } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            {error ? (
              <Error
                code="500"
                title="There was some errors while loading the page."
                list={[
                  "Please reload the page and try again.",
                  "If this error is persisting please contact us from info@statusnap.com",
                ]}
              />
            ) : (
              <Suspense fallback={<div>loading...</div>}>
                <CssBaseline />
                <App />
              </Suspense>
            )}
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    );
  }
}
ReactDOM.createRoot(document.getElementById("root")).render(<Logic />);
