import { CircularProgress } from "@mui/material";
import React from "react";

const Page = (props) => (
  <div className="page-wrap">
    {props.disabled ? null : (
      <div className="layout-header">
        {/* {props.header || (
          <Breadcrumb size="big">
            <Breadcrumb.Section>
              {props.icon && <Icon name={props.icon} />}
              <span>{props.title}</span>
              {props.subtitle && (
                <span className="description">{props.subtitle}</span>
              )}
            </Breadcrumb.Section>
            {props.breads}
          </Breadcrumb>
        )} */}
        {props.extra}
      </div>
    )}
    <div className={"layout-body"}>{props.children}</div>
  </div>
);

Page.Loader = React.memo(() => (
  <div
    className="layout-center"
    style={{
      height: "105vh",
      alignContent: "center",
      textAlign: "center",
    }}
  >
    {/* <RawLoader active inline="centered" /> */}
    <CircularProgress />
  </div>
));

export default Page;
