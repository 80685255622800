import React from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { register, logout, getProfile, login } from "../actions/access.actions";
import toggleLogin from "../actions/modals.actions";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import "../scss/world.scss";
import Background from "../components/world.js";
import { post } from "../util/api.js";
import { Alert } from "@mui/material";

const RegisterFormValidation = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email.")
    .required("Email is required!"),
  name: Yup.string().required("Name is required!"),
  surname: Yup.string().required("Surname is required!"),
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      "Password must be at least 8 characters long and contain one uppercase, one lowercase, one number, and one special character."
    )
    .required("Password is required!"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords do not match!")
    .required("Password confirmation is required."),
});

const LoginFormValidation = Yup.object().shape({
  email: Yup.string()
    .email("E-mail is not valid!")
    .required("E-mail is required!"),
  password: Yup.string()
    .min(6, "Password has to be longer than 6 characters!")
    .required("Password is required!"),
});

const ForgotFormValidation = Yup.object().shape({
  email: Yup.string()
    .email("E-mail is not valid!")
    .required("E-mail is required!"),
});
const ResetFormValidation = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      "There must be at least 8 and one uppercase, lowercase, number and special character."
    )
    .required("Password required!"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password")], "The passwords are not the same!")
    .required("Verify password is mandatory."),
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="primary.secondTextColor"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <a
        color="white"
        style={{ color: "#e6eaeb" }}
        href="https://statusnap.com"
        target="_blank"
        rel="noreferrer"
      >
        Statusnap
      </a>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const RegisterForm = (props) => {
  React.useEffect(() => {
    document.title = "Register | Statusnap";
  }, []);
  if (props.userObj && props.userObj.isAuthenticated)
    return <Redirect to={{ pathname: "/" }} />;
  return (
    <div>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: "primary.background",
            display: { xs: "none", sm: "block" }, // Hide on mobile view
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Background item xs={false} sm={4} md={7} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{ backgroundColor: "primary.backgroundHeader" }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                height: 233,
                width: 350,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt="Statusnap"
              src={"./images/logo.svg"}
            />
            <Typography component="h1" variant="h5" sx={{ color: "white" }}>
              Create Your FREE Account.
            </Typography>
            <Formik
              initialValues={{
                email: "",
                name: "",
                surname: "",
                password: "",
                passwordConfirmation: "",
              }}
              validationSchema={RegisterFormValidation}
              onSubmit={(values, { setSubmitting }) => {
                props.registerUser(
                  values.email,
                  values.password,
                  values.name,
                  values.surname
                );
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Field
                    as={TextField}
                    className="custom-textfield"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    color="secondary"
                    helperText={<ErrorMessage name="name" />}
                    error={Boolean(ErrorMessage.name)}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: "white",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#c9c9c9",
                        },
                        "&:hover fieldset": {
                          borderColor: "#15a32a",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#c9c9c9",
                      },
                      "& .MuiFormHelperText-root": {
                        color: "#c9c9c9",
                      },
                    }}
                  />
                  <Field
                    as={TextField}
                    className="custom-textfield"
                    margin="normal"
                    required
                    fullWidth
                    id="surname"
                    label="Surname"
                    name="surname"
                    autoComplete="surname"
                    color="secondary"
                    helperText={<ErrorMessage name="surname" />}
                    error={Boolean(ErrorMessage.surname)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: {
                        color: "white",
                        caretColor: "white",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#c9c9c9",
                        },
                        "&:hover fieldset": {
                          borderColor: "#15a32a",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#c9c9c9",
                      },
                      "& .MuiFormHelperText-root": {
                        color: "#c9c9c9",
                      },
                    }}
                  />
                  <Field
                    as={TextField}
                    margin="normal"
                    className="custom-textfield"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    color="secondary"
                    helperText={<ErrorMessage name="email" />}
                    error={Boolean(ErrorMessage.email)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: {
                        color: "white",
                        caretColor: "white",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#c9c9c9",
                        },
                        "&:hover fieldset": {
                          borderColor: "#15a32a",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#c9c9c9",
                      },
                      "& .MuiFormHelperText-root": {
                        color: "#c9c9c9",
                      },
                    }}
                  />
                  <Field
                    as={TextField}
                    margin="normal"
                    className="custom-textfield"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    color="secondary"
                    helperText={<ErrorMessage name="password" />}
                    error={Boolean(ErrorMessage.password)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: {
                        color: "white",
                        caretColor: "white",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#c9c9c9",
                        },
                        "&:hover fieldset": {
                          borderColor: "#15a32a",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#c9c9c9",
                      },
                      "& .MuiFormHelperText-root": {
                        color: "#c9c9c9",
                      },
                    }}
                  />
                  <Field
                    as={TextField}
                    margin="normal"
                    className="custom-textfield"
                    required
                    fullWidth
                    name="passwordConfirmation"
                    label="Password Confirmation"
                    type="password"
                    id="passwordConfirmation"
                    autoComplete="current-password"
                    color="secondary"
                    helperText={<ErrorMessage name="passwordConfirmation" />}
                    error={Boolean(ErrorMessage.passwordConfirmation)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: {
                        color: "white",
                        caretColor: "white",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#c9c9c9",
                        },
                        "&:hover fieldset": {
                          borderColor: "#15a32a",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#c9c9c9",
                      },
                      "& .MuiFormHelperText-root": {
                        color: "#c9c9c9",
                      },
                    }}
                  />
                  {props.error && props.error.message && (
                    <Alert severity="error">
                      <span style={{ width: "240px", display: "block" }}>
                        {props.error.message}
                      </span>
                    </Alert>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    color="secondary"
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isSubmitting}
                  >
                    Try For Free
                  </Button>
                  <Grid container spacing={1} direction="column">
                    <Grid item xs fontSize={14} style={{ color: "#c9c9c9" }}>
                      By submitting this form, you are accepting our
                      <a
                        style={{
                          textDecorationLine: "none",
                          color: "white",
                          cursor: "pointer",
                          "&:hover": {
                            color: "white",
                          },
                          "&:focus": {
                            color: "white",
                          },
                        }}
                        href="https://www.statusnap.com/terms-and-conditions"
                        color="secondary"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Terms & Conditions
                      </a>{" "}
                      and
                      <a
                        style={{
                          textDecorationLine: "none",
                          color: "white",
                          cursor: "pointer",
                          "&:hover": {
                            color: "white",
                          },
                          "&:focus": {
                            color: "white",
                          },
                        }}
                        href="https://www.statusnap.com/privacy-policy"
                        color="secondary"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Privacy Policy
                      </a>
                      .
                    </Grid>
                    <Grid item>
                      <span style={{ color: "#c9c9c9" }}>
                        Already have an account?
                        <Link
                          to="/login"
                          variant="body2"
                          color="secondary"
                          style={{
                            textDecorationLine: "none",
                            color: "white",
                            cursor: "pointer",
                            "&:hover": {
                              color: "white",
                            },
                            "&:focus": {
                              color: "white",
                            },
                          }}
                        >
                          {" Login."}
                        </Link>
                      </span>
                    </Grid>
                    <Copyright sx={{ mt: 5 }} />
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

const LoginForm = (props) => {
  React.useEffect(() => {
    document.title = "Login | Statusnap";
  }, []);

  if (props.userObj && props.userObj.isAuthenticated) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  const handleSubmit = (values) => {
    props.loginUser(values.email, values.password);
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundColor: "primary.background",
          display: { xs: "none", sm: "block" }, // Hide on mobile
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Background item xs={false} sm={4} md={7} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{ backgroundColor: "primary.backgroundHeader" }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 233,
              width: 350,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            alt="Statusnap"
            src={"./images/logo.svg"}
          />
          <Typography component="h1" variant="h5" sx={{ color: "white" }}>
            Welcome Back!
          </Typography>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginFormValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  className="custom-textfield"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  placeholder="Email Address"
                  autoComplete="email"
                  autoFocus
                  color="secondary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: {
                      color: "white",
                      caretColor: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#c9c9c9",
                      },
                      "&:hover fieldset": {
                        borderColor: "#15a32a",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#c9c9c9",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#c9c9c9",
                    },
                  }}
                />
                <TextField
                  margin="normal"
                  className="custom-textfield"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  placeholder="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  color="secondary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: {
                      color: "white",
                      caretColor: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#c9c9c9",
                      },
                      "&:hover fieldset": {
                        borderColor: "#15a32a",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#c9c9c9",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#c9c9c9",
                    },
                  }}
                />
                {props.error && props.error.message && (
                  <Alert severity="error">
                    <span style={{ width: "240px", display: "block" }}>
                      {props.error.message}
                    </span>
                  </Alert>
                )}
                <Button
                  type="submit"
                  fullWidth
                  color="secondary"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Grid
                  container
                  sx={{ flexDirection: { xs: "column", md: "row" } }}
                >
                  <Grid item xs>
                    <Link
                      to="/forgot"
                      variant="body2"
                      style={{
                        textDecorationLine: "none",
                        color: "#c9c9c9",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#c9c9c9",
                        },
                        "&:focus": {
                          color: "#c9c9c9",
                        },
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      to="/register"
                      variant="body2"
                      style={{
                        textDecorationLine: "none",
                        color: "#c9c9c9",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#c9c9c9",
                        },
                        "&:focus": {
                          color: "#c9c9c9",
                        },
                      }}
                    >
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            )}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};

export const ForgotPage = (props) => {
  const [sending, setSending] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    document.title = "Forgot Password | Statusnap";
  }, []);

  if (props.userObj && props.userObj.isAuthenticated) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  const handleSubmit = (values) => {
    if (sending) return;
    setSending(true);
    post("/mail/resetPassRequest", {
      email: values.email,
    })
      .then((data) => {
        setSending(false);
        if (data.data.success) {
          setRedirect(values.email);
        } else {
          setError(data.data.message || "Error..!");
        }
      })
      .catch((err) => {
        setSending(false);
        setError(err || "Error..!");
      });
  };
  if (redirect)
    return (
      <Redirect to={{ pathname: "/check/mail", search: "?mail=" + redirect }} />
    );
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundColor: "primary.background",
          display: { xs: "none", sm: "block" }, // Hide on mobile
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Background item xs={false} sm={4} md={7} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{ backgroundColor: "primary.backgroundHeader" }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link
            style={{
              textDecorationLine: "none",
              color: "#c9c9c9",
              marginTop: 15,
              width: "100%",
              cursor: "pointer",
              "&:hover": {
                color: "#c9c9c9",
              },
              "&:focus": {
                color: "#c9c9c9",
              },
            }}
            to="/"
            variant="body2"
            color="secondary"
          >
            {" "}
            Go to Login Page
          </Link>
          <Box
            component="img"
            sx={{
              height: 233,
              width: 350,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            alt="Statusnap"
            src={"./images/logo.svg"}
          />
          <Typography component="h1" variant="h5" sx={{ color: "white" }}>
            Forgot Password
          </Typography>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={ForgotFormValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  className="custom-textfield"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  color="secondary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: {
                      color: "white",
                      caretColor: "white",
                    },
                  }}
                />
                {error && (
                  <Alert severity="error">
                    <span style={{ width: "240px", display: "block" }}>
                      {error}
                    </span>
                  </Alert>
                )}
                <Button
                  type="submit"
                  fullWidth
                  color="secondary"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Send Email
                </Button>

                <Copyright sx={{ mt: 5 }} />
              </Box>
            )}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};

export const CheckMailPage = (props) => {
  React.useEffect(() => {
    document.title = "Check email | Statusnap";
  }, []);

  var urlParams = new URLSearchParams(props.location.search);

  var query = urlParams.get("mail");

  if (props.userObj && props.userObj.isAuthenticated) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundColor: "primary.background",
          display: { xs: "none", sm: "block" }, // Hide on mobile
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Background item xs={false} sm={4} md={7} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{ backgroundColor: "primary.backgroundHeader" }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 233,
              width: 350,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            alt="Statusnap"
            src={"./../images/logo.svg"}
          />
          <Typography component="h1" variant="h5" sx={{ color: "white" }}>
            Password reset link sent.
          </Typography>
          <Typography
            component="h2"
            variant="h5"
            style={{ marginTop: 5, textAlign: "center" }}
            sx={{ color: "white" }}
          >
            Please check your email.{"\n"}
            {query}
          </Typography>

          <Link
            style={{
              textDecorationLine: "none",
              color: "#c9c9c9",
              marginTop: 15,
              cursor: "pointer",
              "&:hover": {
                color: "#c9c9c9",
              },
              "&:focus": {
                color: "#c9c9c9",
              },
            }}
            to="/"
            variant="body2"
            color="secondary"
          >
            {" "}
            Go to Login Page
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

export const ResetPasswordPage = () => {
  const [sending, setSending] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    document.title = "Reset Password | Statusnap";
  }, []);

  const handleSubmit = (values) => {
    if (sending) return;
    setSending(true);
    post("/mail/changePass", {
      password: values.password,
      token: window.location.pathname.replace("/verify/reset/", ""),
    })
      .then((data) => {
        setSending(false);
        if (data.data.success) {
          setRedirect(values.password);
        } else {
          setError(data.data.message || "Error..!");
        }
      })
      .catch((err) => {
        setSending(false);
        setError(err.message);
      });
  };
  if (redirect) return <Redirect to={{ pathname: "/login" }} />;
  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundColor: "primary.background",
          display: { xs: "none", sm: "block" }, // Hide on mobile
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Background item xs={false} sm={4} md={7} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{ backgroundColor: "primary.backgroundHeader" }}
      >
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 233,
              width: 350,
              maxHeight: { xs: 233, md: 167 },
              maxWidth: { xs: 350, md: 250 },
            }}
            alt="Statusnap"
            src={"./../../images/logo.svg"}
          />
          <Typography component="h1" variant="h5" sx={{ color: "white" }}>
            Reset my password
          </Typography>
          <Formik
            initialValues={{
              password: "",
              passwordConfirmation: "",
            }}
            validationSchema={ResetFormValidation}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  className="custom-textfield"
                  required
                  fullWidth
                  id="password"
                  label="New password"
                  name="password"
                  autoComplete="password"
                  autoFocus
                  type="password"
                  color="secondary"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: {
                      color: "white",
                      caretColor: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#c9c9c9",
                      },
                      "&:hover fieldset": {
                        borderColor: "#15a32a",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#c9c9c9",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#c9c9c9",
                    },
                  }}
                />
                <TextField
                  margin="normal"
                  className="custom-textfield"
                  required
                  fullWidth
                  id="passwordConfirmation"
                  label="Verify password"
                  name="passwordConfirmation"
                  autoComplete="password"
                  color="secondary"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passwordConfirmation}
                  error={
                    touched.passwordConfirmation &&
                    Boolean(errors.passwordConfirmation)
                  }
                  helperText={
                    touched.passwordConfirmation && errors.passwordConfirmation
                  }
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: {
                      color: "white",
                      caretColor: "white",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#c9c9c9",
                      },
                      "&:hover fieldset": {
                        borderColor: "#15a32a",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "#c9c9c9",
                    },
                    "& .MuiFormHelperText-root": {
                      color: "#c9c9c9",
                    },
                  }}
                />
                {error && (
                  <Alert severity="error">
                    <span style={{ width: "240px", display: "block" }}>
                      {error}
                    </span>
                  </Alert>
                )}
                <Button
                  type="submit"
                  fullWidth
                  color="secondary"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Renew my password
                </Button>

                <Copyright sx={{ mt: 5 }} />
              </Box>
            )}
          </Formik>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  userObj: state.access.user,
  error: state.access.error,
  loading: state.access.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(getProfile()),
  logoutUser: () => dispatch(logout()),
  loginUser: (email, password) => dispatch(login(email, password)),
  toggleLogin: (newState) => dispatch(toggleLogin(newState)),
});

export const LoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);

export const RegisterPage = connect(mapStateToProps, (dispatch) => ({
  registerUser: (email, password, name, surname) =>
    dispatch(register(email, password, name, surname)),
}))(RegisterForm);
