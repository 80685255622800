import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
} from "@mui/material";
import { CustomButton } from "../helper/Buttons";
import { IconSend } from "@tabler/icons-react";
import { post } from "../util/api";

class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: "",
      step: 1,
      message: "",
    };
  }

  sendVerificationEmail = async () => {
    try {
      await post("/mail/sendVerification").then((data) => {
        if (data.data.success) {
          this.setState({
            step: 2,
            message:
              "A verification email has been sent to your email address.",
          });
        } else {
          this.setState({
            message: data.data.message || "Something went wrong.",
          });
        }
      });
    } catch (error) {
      this.setState({ message: "An error occurred. Please try again." });
    }
  };

  verifyCode = async () => {
    const { email, verificationCode } = this.state;
    try {
      await post("/mail/validateVerification", {
        email,
        code: verificationCode,
      }).then((data) => {
        if (data.data.success) {
          this.setState({ message: "Your email has been verified!" });
          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
        } else {
          this.setState({
            message: data.data.message || "Invalid verification code.",
          });
        }
      });
    } catch (error) {
      this.setState({ message: "An error occurred. Please try again." });
    }
  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleVerificationCodeChange = (event) => {
    this.setState({ verificationCode: event.target.value });
  };

  render() {
    const { verificationCode, step, message } = this.state;
    console.log("this.props ", this);
    return (
      <Container maxWidth="sm" sx={{ mt: 4, textAlign: "center" }} id="test">
        <Box
          component="img"
          className="top-div"
          sx={{
            maxHeight: { xs: 221, md: 330 },
            maxWidth: { xs: 221, md: 330 },
            px: 0,
            py: 0,
            mb: 5,
            mt: 5,
          }}
          alt="Statusnap Logo"
          src={"./images/logo.svg"}
        />
        {step === 1 ? (
          <Box sx={{ textAlign: "center", color: "#c9c9c9" }}>
            <Typography variant="h5" gutterBottom>
              Email Verification
            </Typography>
            <Typography variant="body1" gutterBottom>
              To start the verification process, simply click the button below.
              A verification code will be sent to your email address.
            </Typography>

            <CustomButton
              text="Send Verification Email"
              icon={<IconSend />}
              sx={{ mt: 2, mb: 2, width: "100%" }}
              disableElevation
              onClick={this.sendVerificationEmail}
            />
          </Box>
        ) : (
          <Box sx={{ textAlign: "center", color: "#c9c9c9" }}>
            <Typography variant="h5" gutterBottom>
              Enter Verification Code
            </Typography>
            <Typography variant="body1" gutterBottom>
              Check your email for the verification code. If you don't see it in
              your inbox, please check your spam or junk folder.
            </Typography>

            <TextField
              margin="normal"
              className="custom-textfield"
              required
              fullWidth
              id="verification_code"
              label="Verification Code"
              name="Verification Code"
              color="secondary"
              type="text"
              onChange={this.handleVerificationCodeChange}
              value={verificationCode}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                style: {
                  color: "white",
                  caretColor: "white",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#c9c9c9",
                  },
                  "&:hover fieldset": {
                    borderColor: "#15a32a",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "#c9c9c9",
                },
                "& .MuiFormHelperText-root": {
                  color: "#c9c9c9",
                },
              }}
            />

            <CustomButton
              text="Verify"
              icon={<IconSend />}
              sx={{ mt: 2, mb: 2, width: "100%" }}
              disableElevation
              onClick={this.verifyCode}
            />
          </Box>
        )}
        {message && (
          <Alert severity="info" sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
});

export default connect(mapStateToProps)(Verification);
