/* eslint-disable react/no-children-prop */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Box, Typography, Link } from "@mui/material";
import _ from "lodash";
import { CustomButton } from "../helper/Buttons";
import { Link as RouterLink } from "react-router-dom";
import DashboardCard from "../components/dashboard/dashboardCard";
import { IconArrowBack, IconEdit } from "@tabler/icons-react";
import { post } from "../util/api";
import { countries } from "../helper/detailSettings";
import Page from "../components/Page";
import moment from "moment";
import StatusesPreview from "../components/dashboard/StatusesPreview";
import { getDaysValue } from "../helper/generalControl";

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:
        (this.props.match.params.id &&
          this.props.match.params.id !== "create") ||
        this.props.match.params.id,
      id: this.props.match.params.id || null,
      value: "appereance",
      server_: [],
      redirect: false,
      settings: {},
      server_country: "helsinki",
      days: 30,
    };
  }

  componentDidMount() {
    this.findTemplate();
    this.getUptimes(this.state.server_country);
    if (window) {
      window.addEventListener("resize", this.handleResize, true);
      this.handleResize();
    }
  }

  handleResize = () => {
    var day = 90;
    if (window && window.innerWidth) day = window.innerWidth;
    let res = getDaysValue(day);
    this.setState({ days: res });
  };

  async getUptimes(server, in_project) {
    let { server_country, id } = this.state;
    let check = in_project || server_country || "helsinki";
    let found_country = _.find(countries, (a) => {
      if (a.key == check) {
        return a;
      }
    });
    await post(`/statusnap/uptime/${server}`, {})
      .then((data) => {
        if (data && data.data && data.data.success) {
          let { data: data_ } = data.data;
          let found_ = _.find(data_, (e) => e.id == id);
          this.setState({ server_: found_ });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      loading: false,
      found_country,
      server_country: in_project || server_country,
    });

    this.handlePopoverClose();
  }

  handlePopoverOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget, openPopover: true });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, openPopover: false });
  };

  async findTemplate() {
    let { user } = this.props;
    let { servers } = user;
    let found = _.find(servers, (e) => e.id == this.state.id);
    if ((!this.state.id || this.state.id != "create") && !found) {
      this.setState({ redirect: true });
    } else {
      if (this.state.id == "create") {
        document.title = "Monitoring Page | Statusnap";
      } else {
        document.title = "Monitoring Page | Statusnap";
      }
      this.setState({ settings: found });
    }
  }

  handleChange = (textContent, filtered = null) => {
    this.setState({
      value: textContent,
    });
  };

  render() {
    let { value, redirect, settings, server_, loading, days } = this.state;
    console.log("this22 ", server_);
    console.log("thissettings22 ", settings);
    let crashed_times = 0;
    let crash = server_ && server_.today && server_.today.today_downlist;
    if (crash && crash.length > 0) {
      crash.map((e) => {
        crashed_times += e.down;
      });
    }

    if (redirect) return <Redirect to={{ pathname: "/monitors" }} />;
    // if (!settings || settings.length == undefined) {
    //   return (
    //     <Container style={{ padding: 0, textAlign: "center" }}>
    //       <Typography variant="h6" sx={{ color: "white", marginBottom: 1 }}>
    //         There is currently no details.
    //       </Typography>
    //     </Container>
    //   );
    // }
    if (!server_ || (server_.length == 0 && loading))
      return <Page.Loader key="loader" />;
    return (
      <>
        <Box pb={2}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <CustomButton
              text="Monitoring"
              icon={<IconArrowBack />}
              disableElevation
              component={RouterLink}
              to="/monitors"
            />

            {this.state.id != "create" ? (
              <CustomButton
                text="Edit"
                icon={<IconEdit />}
                disableElevation
                component={RouterLink}
                sx={{ marginLeft: "auto" }}
                to={`/monitors/${this.state.id}`}
              />
            ) : (
              <></>
            )}
          </Box>

          {settings &&
          settings.server_settings &&
          settings.server_settings.link ? (
            <Typography
              sx={{ color: "white", fontSize: "25px", marginTop: "12px" }}
            >
              Edit{" "}
              <Typography
                sx={{ display: "inline", fontSize: "25px", color: "#15a32a" }}
              >
                {settings.server_settings.link}
              </Typography>{" "}
              status page.
            </Typography>
          ) : (
            <></>
          )}
          {settings && settings.id ? (
            <>
              <Typography
                sx={{ display: "inline", color: "white", fontSize: "12px" }}
              >
                {settings && settings.public ? "Public" : "Private"}{" "}
                {settings && settings.type == "https" && "HTTP(S)"} monitor,
                hosted on{" "}
              </Typography>
              <Typography
                sx={{ display: "inline", fontSize: "12px", color: "#15a32a" }}
              >
                <Link
                  href={`https://dns.statusnap.com?p=${settings.id}`}
                  target="_blank"
                  sx={{ color: "#15a32a" }}
                >
                  https://dns.statusnap.com?p={settings.id}
                </Link>
              </Typography>
            </>
          ) : (
            <></>
          )}
        </Box>
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
          <Box flex={{ xs: "1 1 auto", md: "0 0 80%" }} pr={2}>
            <Box mb={4} sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <Box
                sx={{
                  flexGrow: 1,
                  flexBasis: 0,
                  flexShrink: 1,
                  minHeight: "135px",
                }}
              >
                <DashboardCard
                  title=" "
                  subtitle="Current status"
                  children={
                    <a
                      style={{
                        color:
                          settings && settings.is_down
                            ? "red"
                            : !settings.active
                            ? "gray"
                            : "#008001",
                        fontWeight: "bold",
                        fontSize: 15,
                        marginTop: "-5px",
                      }}
                    >
                      {settings && settings.is_down
                        ? "Down"
                        : !settings.active
                        ? "Paused"
                        : "Up"}
                      <div>
                        <a
                          style={{
                            fontWeight: 200,
                            fontSize: 13,
                            color: "#fff",
                          }}
                        >
                          Last Month Uptime{" "}
                          <a>
                            {(server_ &&
                              server_.today &&
                              server_.today.last_month_uptime) ||
                              "~"}
                            %
                          </a>
                        </a>
                      </div>
                    </a>
                  }
                ></DashboardCard>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  flexBasis: 0,
                  flexShrink: 1,
                  height: "100px",
                }}
              >
                <DashboardCard
                  title=" "
                  subtitle="Last check"
                  children={
                    <a
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                    >
                      {server_ &&
                        server_.updated_at &&
                        moment(server_.updated_at).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      <div>
                        <a style={{ fontWeight: 100, fontSize: 13 }}>
                          Checked every{" "}
                          {settings.control < 1
                            ? settings.control * 60 + " second(s)"
                            : settings.control + " minute(s)"}
                        </a>
                      </div>
                    </a>
                  }
                ></DashboardCard>
              </Box>
            </Box>

            <Box mb={2}>
              <DashboardCard title="Today.">
                <Box mb={5}>
                  <Typography
                    style={{
                      color: "#a8a8a8",
                      fontSize: 13,
                      marginRight: "auto",
                    }}
                  >
                    <div>Is Today Down?</div>
                    {server_ && server_.today && server_.today.today_is_down
                      ? `It crashed for ${crashed_times} minutes`
                      : !settings.active
                      ? "Uptime not works. :("
                      : "Everything's fine."}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    style={{
                      color: "#a8a8a8",
                      fontSize: 13,
                      marginRight: "auto",
                    }}
                  >
                    <div>Average</div>
                    {(server_ &&
                      server_.today &&
                      server_.today.today_average) ||
                      "~"}{" "}
                    ms
                  </Typography>{" "}
                  <Typography
                    style={{
                      color: "#a8a8a8",
                      fontSize: 13,
                      marginRight: "auto",
                    }}
                  >
                    <div>Minimum</div>
                    {(server_ && server_.today && server_.today.min_sec) ||
                      "~"}{" "}
                    ms
                  </Typography>
                  <Typography
                    style={{
                      color: "#a8a8a8",
                      fontSize: 13,
                      marginRight: "auto",
                    }}
                  >
                    <div>Maximum</div>
                    {(server_ && server_.today && server_.today.max_sec) ||
                      "~"}{" "}
                    ms
                  </Typography>
                  <Typography
                    style={{
                      color: "#a8a8a8",
                      fontSize: 13,
                      marginRight: "auto",
                    }}
                  >
                    <div>Today Apdex</div>
                    {server_ &&
                      server_.today &&
                      server_.today.today_apdex &&
                      server_.today.today_apdex.toFixed(3)}
                  </Typography>
                </Box>
              </DashboardCard>
            </Box>
            <Box mb={2}>
              <DashboardCard title={days + " days history."}>
                <StatusesPreview
                  key={"preview-detail"}
                  data={settings}
                  servers_={server_}
                  margin={"20px"}
                  solo={true}
                />
              </DashboardCard>
            </Box>
            <Box mb={2}>
              <DashboardCard title={"Latest Incidents."}></DashboardCard>
            </Box>
          </Box>
          <Box flex={{ xs: "1 1 auto", md: "0 0 20%" }} pb={2}>
            <Box mb={2}>
              <DashboardCard title="Domain & SSL"></DashboardCard>
            </Box>
            <Box mb={2}>
              <DashboardCard title="Next maintenance"></DashboardCard>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
}))(Detail);
