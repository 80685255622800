import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#bbbbbb12",
      second: "gray",
      background: "#131a25",
      backgroundHeader: "#151f2f",
      textColor: "#e6eaeb",
      secondTextColor: "#c9c9c9",
    },
    secondary: {
      main: "#008000cd",
      backgroundVis: "#131a25", //"#0080002b",
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
