const getDaysValue = (day) => {
  let res = 30;
  if (day <= 640) res = 30;
  else if (day > 640 && day < 900) res = 60;
  else if (day > 900 && day < 1200) res = 60;
  else if (day > 1200 && day < 1500) res = 60;
  else res = 90;

  return res;
};

module.exports = { getDaysValue };
