/* eslint-disable react/jsx-key */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tab,
  Tabs,
} from "@mui/material";
import {
  IconBrandAndroid,
  IconBrandApple,
  IconBrandDiscord,
  IconBrandTelegram,
  IconBrandZapier,
  IconSearch,
  IconWebhook,
} from "@tabler/icons-react";
import { AvailableOnlyAlert, SoonButton } from "../helper/Buttons";
import _ from "lodash";

const categories = [
  { text: "all", key: "all" },
  { text: "chat platforms", key: "chat platforms" },
  { text: "webhook", key: "webhook" },
  { text: "push notifications", key: "push notifications" },
];

const integrations = [
  {
    id: "webhook",
    name: "Webhook",
    soon: true,
    description:
      "For advanced alerting, you can set up webhooks to integrate with your own system or app.",
    tier: ["team", "enterprise"],
    icon: <IconWebhook lightingColor={15} color="#E3175E" size={36} />,
    categories: ["webhook"],
  },
  {
    id: "telegram",
    name: "Telegram",
    soon: true,
    description:
      "Slack messages are an effective way to notify the entire team about downtime.",
    tier: ["solo", "team", "enterprise"],
    icon: <IconBrandTelegram color="#29A4E5" size={36} />,
    categories: ["chat platforms"],
  },
  {
    id: "discord",
    name: "Discord",
    soon: false,
    description:
      "Telegram messages are an excellent way to stay informed and alert.",
    tier: null,
    icon: <IconBrandDiscord color="#5661EC" size={36} />,
    categories: ["chat platforms"],
  },
  {
    id: "zapier",
    name: "Zapier",
    soon: true,
    description: "Integrate your Zapier account for immediate alerts.",
    tier: ["team", "enterprise"],
    icon: <IconBrandZapier color="#F84700" size={36} />,
    categories: null,
  },
  {
    id: "android",
    name: "Android",
    soon: true,
    description:
      "Receive push notifications for uptime and downtime events on the Statusnap Android app.",
    download: true,
    tier: null,
    icon: <IconBrandAndroid color="#9FC039" size={36} />,
    categories: ["push notifications"],
  },
  {
    id: "ios",
    name: "IOS",
    soon: true,
    description:
      "Receive push notifications for events on your iPhone using the Statusnap app.",
    download: true,
    tier: null,
    icon: <IconBrandApple color="#000000" size={36} />,
    categories: ["push notifications"],
  },
];

class Integration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      value: "all",
      integrations_new: integrations,
    };
  }
  componentDidMount() {
    document.title = "Integrations | Statusnap";
  }

  changeSearch = (filtered_value) => {
    let { integrations_new, value } = this.state;

    const filtered = integrations_new.filter((item) =>
      item.id.includes(filtered_value.toLowerCase())
    );
    this.handleChange(value, filtered);
  };

  handleChange = (textContent, filtered = null) => {
    let mem_integrations = filtered || integrations;

    if (textContent != "all") {
      mem_integrations = _.filter(
        integrations,
        (e) => e.categories == textContent
      );
    }
    this.setState({
      value: textContent,
      integrations_new: mem_integrations,
    });
  };

  render() {
    let { value, integrations_new } = this.state;
    return (
      <>
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }}>
          <Box flex={{ xs: "1 1 auto", md: "0 0 25%" }} p={2}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={(e) => this.handleChange(e.target.textContent, null)}
              aria-label="Vertical tabs example"
              sx={{ alignItems: { xs: "normal", md: "flex-start" } }}
            >
              {categories.map((e, i) => {
                return (
                  <Tab
                    key={e.key}
                    label={e.text}
                    value={e.key}
                    style={{ alignItems: "flex-start" }}
                    sx={{
                      color: (theme) => theme.palette.primary.secondTextColor,
                      fontWeight: "bold",

                      background: value == e.key ? "#131a25" : "transparent",
                      borderRadius: value == e.key ? "5px" : "none",
                      "&.Mui-selected": {
                        color: "#c4c4c4",
                      },
                    }}
                  />
                );
              })}
            </Tabs>
          </Box>
          <Box flex={{ xs: "1 1 auto", md: "0 0 75%" }} p={2}>
            <FormControl sx={{ m: 1, width: "95%" }}>
              <InputLabel
                htmlFor="outlined-adornment-amount"
                sx={{
                  color: (theme) => theme.palette.primary.secondTextColor,
                  "&.Mui-focused": { color: "white" },
                }}
              >
                Search by integration name
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                onChange={(e) => {
                  let { value } = e.target;
                  this.changeSearch(value);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <IconSearch
                      size={15}
                      sx={{
                        color: (theme) => theme.palette.primary.secondTextColor,
                      }}
                    />
                  </InputAdornment>
                }
                label="Search by integration name"
                className="custom-nonetextfield"
                sx={{
                  caretColor: "white",
                  color: "white",
                  height: 35,
                  "& .MuiOutlinedInput-root": {
                    color: (theme) => theme.palette.primary.secondTextColor,
                  },
                  "& .MuiInputAdornment-root": {
                    color: (theme) => theme.palette.primary.secondTextColor,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: (theme) =>
                      theme.palette.primary.secondTextColor,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: (theme) =>
                      theme.palette.primary.secondTextColor,
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: (theme) =>
                      theme.palette.primary.secondTextColor,
                  },
                }}
              />
            </FormControl>
            {integrations_new.map((e, i) => (
              <Box
                key={"integ-" + i}
                sx={{
                  mb: 1,
                  mt: 1,
                  background: "#131a25",
                  padding: "10px 0 10px 10px",
                  color: "#c9c9c9",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>{e.icon}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "5px",
                    flex: "30%",
                  }}
                >
                  <span style={{ color: "white", fontWeight: "bold" }}>
                    {e.name}
                  </span>
                  <span style={{ fontSize: "12px" }}>{e.description}</span>
                </div>

                {e.soon ? (
                  <SoonButton />
                ) : (
                  <AvailableOnlyAlert style={{ flex: "50%" }} />
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
}))(Integration);
