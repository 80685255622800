import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  useMediaQuery,
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import { IconCircleCheckFilled, IconSearch, IconX } from "@tabler/icons-react";
import { get } from "../util/api";
import Page from "../components/Page";
import moment from "moment";
import _ from "lodash";
import { SortIncidents } from "../helper/detailSettings";
import { getFromLocalStorage, saveToLocalStorage } from "../helper/storage";

const StatusTable = ({ formatted_data, props }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <TableContainer sx={{ backgroundColor: "#131a25" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: (theme) => theme.palette.primary.textColor,
                borderColor: "#151f2f",
              }}
            >
              Root Cause
            </TableCell>
            {!isMobile && (
              <>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.primary.textColor,
                    borderColor: "#151f2f",
                  }}
                >
                  Monitor
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.primary.textColor,
                    borderColor: "#151f2f",
                  }}
                >
                  Label
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.primary.textColor,
                    borderColor: "#151f2f",
                  }}
                >
                  Submitted Date
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.primary.textColor,
                    borderColor: "#151f2f",
                  }}
                >
                  Platform
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.primary.textColor,
                    borderColor: "#151f2f",
                  }}
                >
                  Detail
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {formatted_data.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                textDecoration: "none",
              }}
            >
              <TableCell
                sx={{
                  fontSize: 12,
                  color: (theme) =>
                    row.status == true
                      ? theme.palette.secondary.main
                      : theme.palette.error.main,
                  borderColor: "#151f2f",
                }}
              >
                <Box display="flex" alignItems="center">
                  {row.status == true ? (
                    <IconCircleCheckFilled
                      size={15}
                      style={{ marginRight: 5 }}
                    />
                  ) : (
                    <IconX size={15} style={{ marginRight: 5 }} />
                  )}
                  <span>
                    {(row.extra &&
                      row.extra.message &&
                      parseInt(row.extra.message)) ||
                      "-"}
                  </span>
                </Box>
                {isMobile && (
                  <>
                    <Box sx={{ color: "#c9c9c9" }}>
                      Monitor:{" "}
                      {
                        _.find(
                          props.user && props.user.servers,
                          (e) => e.id == row.server_id
                        )[0].server_settings.link
                      }
                    </Box>
                    <Box sx={{ color: "#c9c9c9" }}>Label: {row.label}</Box>
                    <Box sx={{ color: "#c9c9c9" }}>
                      Submitted Date:{" "}
                      {moment(row.date).format("LLL").replaceAll("/", "-")}
                    </Box>
                    <Box sx={{ color: "#c9c9c9" }}>
                      Platform: {(row.extra && row.extra.type) || "-"}
                    </Box>
                    <Box sx={{ color: "#c9c9c9" }}>
                      Detail: {(row.extra && row.extra.channel) || "-"}
                    </Box>
                  </>
                )}
              </TableCell>
              {!isMobile && (
                <>
                  <TableCell
                    sx={{
                      fontSize: 12,
                      color: (theme) => theme.palette.primary.secondTextColor,
                      borderColor: "#151f2f",
                    }}
                  >
                    {
                      _.find(
                        props.user && props.user.servers,
                        (e) => e.id == row.server_id
                      ).server_settings.link
                    }
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 12,
                      color: (theme) => theme.palette.primary.secondTextColor,
                      borderColor: "#151f2f",
                    }}
                  >
                    {row.label}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 12,
                      color: (theme) => theme.palette.primary.secondTextColor,
                      borderColor: "#151f2f",
                    }}
                  >
                    {moment(row.date).format("LLL").replaceAll("/", "-")}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 12,
                      color: (theme) => theme.palette.primary.secondTextColor,
                      borderColor: "#151f2f",
                    }}
                  >
                    {(row.extra && row.extra.type) || "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 12,
                      color: (theme) => theme.palette.primary.secondTextColor,
                      borderColor: "#151f2f",
                    }}
                  >
                    {(row.extra && row.extra.channel) || "-"}
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

class Incidents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_value: "resolved",
      loading: true,
      formatted_data: [],
      soft_formatted_data: [],
    };
  }

  componentDidMount() {
    document.title = "Incidents | Statusnap";
    this.getIncidents();

    if (getFromLocalStorage("incidents"))
      this.changeFilter(getFromLocalStorage("incidents"), true);
  }

  async getIncidents() {
    await get("/statusnap/incidents/message")
      .then((data) => {
        if (data && data.success) {
          this.setState({
            formatted_data: data.data,
            soft_formatted_data: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ loading: false });
  }

  changeFilter = (new_value, first) => {
    let { formatted_data, soft_formatted_data } = this.state;
    if (first) soft_formatted_data = formatted_data;
    let new_data;
    saveToLocalStorage("incidents", new_value);

    this.setState({ selected_value: new_value });
  };

  changeSearch = (filtered_value) => {
    let { formatted_data, soft_formatted_data } = this.state;
    if (!filtered_value || filtered_value == "") {
      return this.setState({ formatted_data: soft_formatted_data });
    }
    const filtered = formatted_data.filter(
      (item) =>
        item.server_id.includes(filtered_value) ||
        item.extra.url.includes(filtered_value)
    );
    this.setState({ formatted_data: filtered });
  };

  render() {
    let { soft_formatted_data, formatted_data, loading, selected_value } =
      this.state;

    if (loading) return <Page.Loader key="loader" />;
    if (
      !soft_formatted_data ||
      (soft_formatted_data && soft_formatted_data.length <= 0)
    )
      return (
        <Container style={{ padding: 0, textAlign: "center" }}>
          <Typography variant="h6" sx={{ color: "white", marginBottom: 1 }}>
            There is currently no incidents registered.
          </Typography>
        </Container>
      );
    return (
      <>
        <Box
          sx={{
            margin: "7px 7px 7px 0",
            display: "flex",
            "@media screen and (max-width: 600px)": {
              display: "block",
              width: "100%",
            },
          }}
          flex={{ xs: "1 1 auto", md: "0 0 100%" }}
        >
          <FormControl
            sx={{
              mt: 1,
              mb: 1.7,
              "@media screen and (max-width: 600px)": {
                width: "100%",
              },
            }}
          >
            <OutlinedInput
              id="outlined-adornment-amount"
              placeholder="Search by Monitor Name or Id"
              onChange={(e) => {
                let { value } = e.target;
                this.changeSearch(value);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <IconSearch
                    size={15}
                    sx={{
                      color: (theme) => theme.palette.primary.secondTextColor,
                    }}
                  />
                </InputAdornment>
              }
              className="custom-nonetextfield"
              sx={{
                caretColor: "#e0e0e0",
                color: "#e0e0e0",
                height: 35,
                fontSize: 13,
                paddingRight: 1,
                "& .MuiOutlinedInput-root": {
                  color: (theme) => theme.palette.primary.secondTextColor,
                },
                "& .MuiInputAdornment-root": {
                  color: (theme) => theme.palette.primary.secondTextColor,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#dfdfdfa1",
                  borderWidth: "thin",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#dfdfdfa1",
                  borderWidth: "thin",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#dfdfdfa1",
                  borderWidth: "thin",
                },
              }}
            />
          </FormControl>
          <div
            style={{
              marginBottom: "6px",
              marginLeft: "auto",
            }}
          >
            <FormControl
              sx={{
                "@media screen and (max-width: 600px)": {
                  width: "100%",
                },
                width: "200px",
                color: "#727881", // Daha karanlık gri ton
                marginTop: "9px",
                backgroundColor: "#131a25", // Arka plan rengi
                borderRadius: "4px", // Hafif yuvarlatılmış köşeler
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Hafif gölge
              }}
            >
              <Select
                sx={{
                  caretColor: "#727881", // İmleç rengi
                  color: "#c9c9c9", // Varsayılan yazı rengi
                  height: 38,
                  backgroundColor: "#131a25", // İç kısmın arka plan rengi
                  borderRadius: "4px", // Yuvarlatılmış kenarlar
                  "&:hover": {
                    backgroundColor: "#151f2f", // Hover durumunda biraz daha açık arka plan rengi
                    color: "#e0e0e0", // Hover durumunda yazı rengi
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#131a25", // Kenar çizgisi rengi
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#131a25", // Hover durumunda kenar rengi
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#131a25", // Odaklanmış durumda kenar rengi
                  },
                  ".MuiSelect-icon": {
                    color: "#727881",
                  },
                }}
                labelId="dropdown-label"
                value={selected_value}
                label="Sort Status"
                onChange={(e) => {
                  this.changeFilter(e.target.value);
                }}
              >
                {_.map(SortIncidents, (e, i) => {
                  return (
                    <MenuItem
                      sx={{ color: "#c9c9c9" }} // Menü öğelerinin yazı rengi
                      value={e.value}
                      key={"dropdown-" + i}
                    >
                      {e.key}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </Box>
        <StatusTable formatted_data={formatted_data} props={this.props} />
      </>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
}))(Incidents);
