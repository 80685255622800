import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  styled,
  Stack,
  IconButton,
  Badge,
  Button,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Profile from "./sidebar/Profile";
import { IconMenu } from "@tabler/icons-react";

const Header = ({ toggleMobileSidebar, title, logoutUser }) => {
  const AppBarStyled = styled(AppBar)(({ theme }) => ({
    boxShadow: "none",
    background: theme.palette.background.paper,
    justifyContent: "center",
    backdropFilter: "blur(4px)",
    [theme.breakpoints.up("lg")]: {
      minHeight: "70px",
    },
  }));
  const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
    width: "100%",
    color: theme.palette.text.secondary,
  }));

  return (
    <AppBarStyled
      position="sticky"
      color="default"
      style={{ zIndex: 99999999, borderBottom: "0.5px solid #151f2f" }}
      sx={{ backgroundColor: (theme) => theme.palette.primary.background }}
    >
      <ToolbarStyled>
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={toggleMobileSidebar}
          sx={{
            display: {
              lg: "none",
              xs: "inline",
            },
          }}
          style={{ paddingTop: 13 }}
        >
          <IconMenu width="20" height="20" color="white" />
        </IconButton>

        {/* <IconButton
          size="large"
          aria-label="show 11 new notifications"
          color="inherit"
          aria-controls="msgs-menu"
          aria-haspopup="true"
        >
          <Badge
            variant="dot"
            color="primary"
            sx={{
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            <IconBellRinging size="21" stroke="1.5" sx={{ background: "white !important" }} />
          </Badge>
        </IconButton> */}
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.secondTextColor,
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
        <Box flexGrow={1} />
        <Stack spacing={1} direction="row" alignItems="center">
          <Profile logoutUser={logoutUser} />
        </Stack>
      </ToolbarStyled>
    </AppBarStyled>
  );
};

Header.propTypes = {
  sx: PropTypes.object,
  toggleMobileSidebar: PropTypes.func.isRequired,
};

export default Header;
