/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { keyframes } from "@emotion/react";
import MonthlyEarnings from "../components/dashboard/earning";
import ProductPerformance from "../components/dashboard/ProductPerformance";
import RecentTransactions from "../components/dashboard/RecentTransactions";
import SalesOverview from "../components/dashboard/SalesOverview";
import YearlyBreakup from "../components/dashboard/YearlyBreakup";
import StatusesPreview from "../components/dashboard/StatusesPreview";
import DashboardCard from "../components/dashboard/dashboardCard";
import { Box, Popover, Typography } from "@mui/material";
import { post } from "../util/api";
import Page from "../components/Page";
import { Link } from "react-router-dom";
import { getDaysValue } from "../helper/generalControl";
import { countries } from "../helper/detailSettings";

var monitors = [];

const pulse = keyframes`
  0% {
    opacity: 1;
    box-shadow: 0 0 40px #15a32a;
  }
  50% {
    opacity: 0;
    box-shadow: none;
  }
  100% {
    opacity: 1;
    box-shadow: 0 0 40px #15a32a;
  }
`;

const pulseRed = keyframes`
  0% {
    opacity: 1;
    box-shadow: 0 0 40px red;
  }
  50% {
    opacity: 0;
    box-shadow: none;
  }
  100% {
    opacity: 1;
    box-shadow: 0 0 40px red;
  }
`;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      servers_: [],
      days: 30,
      anchorEl: null,
      openPopover: false,
      server_country: "helsinki",
      load_more: false,
    };
  }
  componentDidMount() {
    document.title = "Dashboard | Statusnap";
    this.getUptimes(this.state.server_country);
    if (window) {
      window.addEventListener("resize", this.handleResize, true);
      this.handleResize();
    }
  }

  handlePopoverOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget, openPopover: true });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null, openPopover: false });
  };

  handleResize = () => {
    var day = 90;
    if (window && window.innerWidth) day = window.innerWidth;
    let res = getDaysValue(day);
    this.setState({ days: res });
  };

  componentWillUnmount() {
    if (window) window.removeEventListener("resize", this.handleResize, true);
  }

  calculateTimes(updatedAt, control) {
    control = control * 60;
    const now = new Date();
    const updatedDate = new Date(updatedAt);
    const timeDifferenceInMilliseconds = now - updatedDate;
    const timeDifferenceInSeconds = Math.floor(
      timeDifferenceInMilliseconds / 1000
    );

    return timeDifferenceInSeconds > control + 15 ? false : true;
  }

  async getUptimes(server, in_project) {
    let { server_country } = this.state;
    let check = in_project || server_country || "helsinki";
    let found_country = _.find(countries, (a) => {
      if (a.key == check) {
        return a;
      }
    });
    await post(`/statusnap/uptime/${server}`, {})
      .then((data) => {
        if (data && data.data && data.data.success) {
          let { data: data_ } = data.data;
          this.setState({ servers_: data_ });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.setState({
      loading: false,
      found_country,
      server_country: in_project || server_country,
    });

    this.handlePopoverClose();
  }

  render() {
    let { user, product } = this.props;
    let { subscription } = product;
    let { servers } = user;
    let { servers_, loading, days, server_country, found_country } = this.state;

    let disabled = servers.filter(
      (server) => server.server_settings.is_down
    ).length;
    if (!servers_ || (servers_.length == 0 && loading))
      return <Page.Loader key="loader" />;
    return (
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row", padding: 0 }}
      >
        <Box flex={{ xs: "1 1 auto", md: "0 0 75%" }}>
          <DashboardCard title="Monitors.">
            {!servers || servers.length == 0 ? (
              <Box sx={{ color: "white" }}>
                <Typography sx={{ color: "#ca4024" }}>
                  You do not have active monitors.
                </Typography>
                <Typography>
                  Activate your monitors now! Everything at your fingertips!
                </Typography>
                <Typography
                  style={{ textDecoration: "none", color: "#15a32a" }}
                  component={Link}
                  to={`/monitors/create`}
                >
                  Try free now!
                </Typography>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-25px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#a8a8a8",
                      fontSize: 13,
                      marginRight: "auto",
                    }}
                  >
                    {days} days history.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      cursor: "pointer", // İmleci el işaretine çevir
                    }}
                    mb={2}
                    onClick={this.handlePopoverOpen} // Tıklama olayını ekle
                  >
                    <Box
                      component="img"
                      className="top-div"
                      sx={{
                        height: 20,
                        width: 20,
                        maxHeight: { xs: 20, md: 20 },
                        maxWidth: { xs: 20, md: 20 },
                        px: 0,
                        py: 0,
                      }}
                      alt={found_country.key + "img"}
                      src={found_country.img}
                    />
                    <Typography
                      sx={{ display: "inline-block", color: "#a8a8a8" }}
                      ml={1}
                    >
                      {found_country.value}
                    </Typography>
                    <Typography sx={{ color: "white", marginLeft: "5px" }}>
                      ▼
                    </Typography>
                  </Box>

                  <Popover
                    open={this.state.openPopover}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Box sx={{ background: "#131a25" }}>
                      {_.map(countries, (e) => {
                        if (e.key == server_country) return <></>;
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              cursor: "pointer", // İmleci el işaretine çevir
                              p: 1,
                            }}
                            onClick={() => {
                              this.getUptimes(e.key, e.key);
                            }}
                          >
                            <Box
                              component="img"
                              className="top-div"
                              sx={{
                                height: 20,
                                width: 20,
                                maxHeight: { xs: 20, md: 20 },
                                maxWidth: { xs: 20, md: 20 },
                                px: 0,
                                py: 0,
                                mr: 1,
                              }}
                              alt={e.key + "img"}
                              src={e.img}
                            />
                            <Typography
                              key={e.key}
                              sx={{
                                color:
                                  e.key == server_country ? "#a8a8a8" : "white",
                              }}
                            >
                              {e.value}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Popover>
                </Box>

                {!this.state.load_more ? (
                  <Box
                    sx={{
                      maxHeight: "255px",
                    }}
                  >
                    {_.map(servers.slice(0, 3), (e, i) => {
                      return (
                        <StatusesPreview
                          key={"preview-" + i}
                          data={e}
                          servers_={_.find(servers_, (a) => a.id == e.id)}
                          margin={"20px"}
                        />
                      );
                    })}
                  </Box>
                ) : (
                  <Box
                    className="scroll-new"
                    sx={{
                      maxHeight: "255px",
                      overflowX: "auto",
                    }}
                  >
                    {_.map(servers, (e, i) => {
                      return (
                        <StatusesPreview
                          key={"preview-" + i}
                          data={e}
                          servers_={_.find(servers_, (a) => a.id == e.id)}
                          margin={"8px"}
                        />
                      );
                    })}
                  </Box>
                )}
                <div style={{ textAlign: "right" }}>
                  {!this.state.load_more ? (
                    <Typography
                      sx={{
                        color: "#a8a8a8",
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                      onClick={() => this.setState({ load_more: true })}
                    >
                      More Monitors.
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        color: "#a8a8a8",
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                      onClick={() => this.setState({ load_more: false })}
                    >
                      Less Monitors.
                    </Typography>
                  )}
                </div>
              </>
            )}

            {/* <MonthlyEarnings />
        <br />
        <ProductPerformance />
        <br />
        <SalesOverview />
        <br />
        <YearlyBreakup /> */}
          </DashboardCard>
        </Box>
        <Box
          flex={{ xs: "1 1 auto", md: "0 0 25%" }}
          pl={{ xs: 0, md: 3 }}
          pt={{ xs: 3, md: 0 }}
        >
          <DashboardCard title="Current Status">
            <Box sx={{ textAlign: "center" }}>
              <Box
                component="span"
                sx={{
                  ml: 1,
                  mr: 1,
                  display: "inline-block",
                  width: "3rem",
                  height: "3rem",
                  borderRadius: "50%",
                  backgroundColor: disabled ? "red" : "#15a32a",
                  animation: `${disabled ? pulseRed : pulse} 3s infinite`,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  color: "white",
                  justifyContent: "space-between",
                  mt: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#15a32a",
                  }}
                  flex={{ md: "0 0 33.4%" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Up</Typography>
                  <Typography>
                    {
                      servers_.filter(
                        (server) =>
                          _.find(servers, (e) => e.id == server.id).active &&
                          this.calculateTimes(
                            server.updated_at,
                            _.find(servers, (e) => e.id == server.id).control
                          )
                      ).length
                    }
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#a8a8a8",
                  }}
                  flex={{ md: "0 0 33.4%" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Paused</Typography>
                  <Typography>
                    {
                      servers_.filter(
                        (server) =>
                          !_.find(servers, (e) => e.id == server.id).active ||
                          !this.calculateTimes(
                            server.updated_at,
                            _.find(servers, (e) => e.id == server.id).control
                          )
                      ).length
                    }
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#ca4024",
                  }}
                  flex={{ md: "0 0 33.4%" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}>Down</Typography>
                  <Typography>
                    {
                      servers.filter((server) => server.server_settings.is_down)
                        .length
                    }
                  </Typography>
                </Box>
              </Box>
              <Typography sx={{ color: "gray", mt: 2, fontSize: "13px" }}>
                Using {servers_.length} of {subscription.limit} monitors.
              </Typography>
            </Box>
          </DashboardCard>
          {/* <div style={{ marginTop: 20 }}>
            <DashboardCard title="Today Recents">
              <Box sx={{ textAlign: "center" }}></Box>
            </DashboardCard>
          </div> */}
        </Box>
      </Box>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
}))(Home);
