import {
  IconDashboard,
  IconNetwork,
  IconStatusChange,
  IconBrandTeams,
  IconShieldBolt,
  IconComponents,
} from "@tabler/icons-react";

import { uniqueId } from "lodash";

const Menuitems = [
  // {
  //   navlabel: true,
  //   subheader: "Home",
  // },
  {
    id: uniqueId(),
    title: "Dashboard",
    icon: IconDashboard,
    href: "/",
  },
  // {
  //   navlabel: true,
  //   subheader: "Utilities",
  // },
  {
    id: uniqueId(),
    title: "Monitors",
    icon: IconStatusChange,
    href: "/monitors",
  },
  {
    id: uniqueId(),
    title: "Status Cluster",
    icon: IconComponents,
    href: "/cluster",
  },
  {
    id: uniqueId(),
    title: "Incidents",
    icon: IconShieldBolt,
    href: "/incidents",
  },
  // {
  //   navlabel: true,
  //   subheader: "Team",
  // },
  {
    id: uniqueId(),
    title: "Team Members",
    icon: IconBrandTeams,
    href: "/team",
  },
  // {
  //   navlabel: true,
  //   subheader: "Integrations",
  // },
  {
    id: uniqueId(),
    title: "Integrations",
    icon: IconNetwork,
    href: "/integrations",
  },
];

export default Menuitems;
