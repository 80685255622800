import React from "react";
import { Box, Button, Typography } from "@mui/material";

const PricingBox = ({
  price,
  oldPrice,
  duration,
  packageName,
  popular,
  subtitle,
  isMonthly,
  children,
  monitors,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      {popular ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "#3bd671",
            borderRadius: "8px 8px 0 0",
            py: 1,
          }}
        >
          <Typography sx={{ color: "white" }}>Most Popular</Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            borderRadius: "8px 8px 0 0",
            height: "40px",
          }}
        ></Box>
      )}
      <Box
        sx={{
          boxShadow: 3,
          backgroundColor: "white",
          p: 3,
          borderRadius: "0 0 8px 8px",
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {oldPrice !== price && (
              <Typography
                component="span"
                sx={{ textDecoration: "line-through", mr: 1 }}
              >
                {oldPrice}
              </Typography>
            )}
            ${price}
            <Typography component="span" sx={{ fontWeight: "light", ml: 1 }}>
              /{duration}
            </Typography>
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {packageName}
          </Typography>
        </Box>
        <Typography sx={{ mb: 2 }}>{subtitle}</Typography>
        <Box sx={{ borderBottom: 1, borderColor: "grey.300", pb: 2, mb: 2 }}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: oldPrice === "0" ? "#687790" : "#3bd671",
              "&:hover": {
                backgroundColor: oldPrice === "0" ? "#505b7a" : "#34bf61",
              },
              color: "white",
            }}
            onClick={() =>
              (window.location.href = "https://console.statusnap.com/")
            }
          >
            {oldPrice === "0" ? "Start Free Trial" : "Subscribe"}
          </Button>
        </Box>
        <Box
          sx={{
            mb: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography style={{ fontSize: "15px" }}>
            {monitors} monitors
          </Typography>
        </Box>
        <Box>{children}</Box>
        <Box sx={{ position: "absolute", bottom: 0, right: 0, zIndex: -1 }}>
          <svg
            width="179"
            height="158"
            viewBox="0 0 179 158"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M75.0002 63.256C115.229 82.3657 136.011 137.496 141.374 162.673C150.063 203.47 207.217 197.755 202.419 167.738C195.393 123.781 137.273 90.3579 75.0002 63.256Z"
              fill="#3BD671"
            />
            <path
              opacity="0.3"
              d="M178.255 0.150879C129.388 56.5969 134.648 155.224 143.387 197.482C157.547 265.958 65.9705 295.709 53.1024 246.401C34.2588 174.197 100.939 83.7223 178.255 0.150879Z"
              fill="#3BD671"
            />
            <defs>
              <linearGradient
                id="paint0_linear_70:153"
                x1="69.6694"
                y1="29.9033"
                x2="196.108"
                y2="83.2919"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#4A6CF7" stopOpacity="0.62" />
                <stop offset="1" stopColor="#4A6CF7" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_70:153"
                x1="165.348"
                y1="-75.4466"
                x2="-3.75136"
                y2="103.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#4A6CF7" stopOpacity="0.62" />
                <stop offset="1" stopColor="#4A6CF7" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </Box>
      </Box>
    </Box>
  );
};

export default PricingBox;
