import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Box,
  Container,
  FormControl,
  Icon,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  Popover,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import {
  IconAdjustmentsHorizontal,
  IconCircleCheckFilled,
  IconPlus,
  IconRestore,
  IconSearch,
  IconX,
} from "@tabler/icons-react";
import _, { filter } from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
import Page from "../components/Page";
import { AddButton, CustomButton } from "../helper/Buttons";
import ProductPerformance from "../components/dashboard/ProductPerformance";
import { SortVariables, FilterVariables } from "../helper/detailSettings";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  saveToLocalStorage,
} from "../helper/storage";

const PopoverContent = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  color: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[800]}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[10],
  padding: theme.spacing(2),
  width: "300px",
}));

const StatusTable = ({ formatted_data }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <TableContainer sx={{ backgroundColor: "#131a25" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: (theme) => theme.palette.primary.textColor,
                borderColor: "#151f2f",
              }}
            >
              Status
            </TableCell>
            {!isMobile && (
              <>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.primary.textColor,
                    borderColor: "#151f2f",
                  }}
                >
                  Type
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.primary.textColor,
                    borderColor: "#151f2f",
                  }}
                >
                  Link
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.primary.textColor,
                    borderColor: "#151f2f",
                  }}
                >
                  Monitor Id
                </TableCell>

                <TableCell
                  sx={{
                    color: (theme) => theme.palette.primary.textColor,
                    borderColor: "#151f2f",
                  }}
                >
                  Control
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.primary.textColor,
                    borderColor: "#151f2f",
                  }}
                >
                  Created At
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.primary.textColor,
                    borderColor: "#151f2f",
                  }}
                >
                  State
                </TableCell>
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.primary.textColor,
                    borderColor: "#151f2f",
                  }}
                >
                  Duration
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {formatted_data.map((row, index) => (
            <TableRow
              key={index}
              component={Link}
              to={`/monitor/${row.id}`}
              sx={{
                textDecoration: "none",
                "&:hover": {
                  backgroundColor: "#151f2f",
                },
              }}
            >
              <TableCell
                sx={{
                  fontSize: 12,
                  color: (theme) =>
                    row.status == true
                      ? theme.palette.secondary.main
                      : theme.palette.error.main,
                  borderColor: "#151f2f",
                }}
              >
                <Box display="flex" alignItems="center">
                  {row.status == true ? (
                    <IconCircleCheckFilled
                      size={15}
                      style={{ marginRight: 5 }}
                    />
                  ) : (
                    <IconX size={15} style={{ marginRight: 5 }} />
                  )}
                  <span>{row.status ? "Active" : "Inactive"}</span>
                </Box>
                {isMobile && (
                  <>
                    <Box sx={{ color: "#c9c9c9" }}>Id: {row.id}</Box>
                    <Box sx={{ color: "#c9c9c9" }}>Type: {row.type}</Box>
                    <Box sx={{ color: "#c9c9c9" }}>
                      Monitor Id: {row.monitor}
                    </Box>
                    <Box sx={{ color: "#c9c9c9" }}>Control: {row.control}</Box>
                    <Box sx={{ color: "#c9c9c9" }}>
                      Created At: {row.created_at}
                    </Box>
                    <Box sx={{ color: "#c9c9c9" }}>
                      State: {row.public ? "Public" : "Private"}
                    </Box>
                    <Box sx={{ color: "#c9c9c9" }}>
                      Duration: {row.duration} day(s)
                    </Box>
                  </>
                )}
              </TableCell>
              {!isMobile && (
                <>
                  <TableCell
                    sx={{
                      fontSize: 12,
                      color: (theme) => theme.palette.primary.secondTextColor,
                      borderColor: "#151f2f",
                    }}
                  >
                    {row.type}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 12,
                      color: (theme) => theme.palette.primary.secondTextColor,
                      borderColor: "#151f2f",
                    }}
                  >
                    {row.monitor}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 12,
                      color: (theme) => theme.palette.primary.secondTextColor,
                      borderColor: "#151f2f",
                    }}
                  >
                    {row.id}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 12,
                      color: (theme) => theme.palette.primary.secondTextColor,
                      borderColor: "#151f2f",
                    }}
                  >
                    {row.control}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 12,
                      color: (theme) => theme.palette.primary.secondTextColor,
                      borderColor: "#151f2f",
                    }}
                  >
                    {row.created_at}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 12,
                      color: (theme) => theme.palette.primary.secondTextColor,
                      borderColor: "#151f2f",
                    }}
                  >
                    {row.public ? "Public" : "Private"}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: 12,
                      color: (theme) => theme.palette.primary.secondTextColor,
                      borderColor: "#151f2f",
                    }}
                  >
                    {row.duration} day(s)
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      formatted_data: [],
      soft_formatted_data: [],
      selected_value: "default",
      anchorEl: false,
      checked_list: JSON.parse(getFromLocalStorage("filter")),
    };
  }

  componentDidMount() {
    document.title = "Status | Statusnap";
    let { formatted_data, selected_value } = this.state;
    let { user } = this.props;
    let { servers } = user;
    if (servers && servers.length > 0) {
      _.each(servers, (e) => {
        formatted_data.push({
          status: e.active,
          monitor:
            (e.server_settings && e.server_settings.link) ||
            (e.server_settings && e.server_settings.domain_name) ||
            e.domain,
          id: e.id,
          type: e.type,
          control: e.control < 1 ? e.control * 60 + " sec" : e.control + " min",
          created_at: moment(e.created_at).format("LLL").replaceAll("/", "-"),
          created_at_soft: e.created_at,
          public: e.public,
          duration: Math.floor(
            (new Date().getTime() - new Date(e.created_at).getTime()) /
              (1000 * 60 * 60 * 24)
          ),
        });
      });
      _.sortBy(formatted_data, (o) => {
        return !!o.status;
      });

      this.setState({
        formatted_data,
        soft_formatted_data: formatted_data,
        loading: false,
      });
      if (getFromLocalStorage("dropdown"))
        this.changeFilter(getFromLocalStorage("dropdown"), true);
      if (getFromLocalStorage("filter"))
        this.changeFilter2Start(null, null, true);
    } else {
      this.setState({
        formatted_data,
        soft_formatted_data: formatted_data,
        loading: false,
      });
    }
  }

  changeSearch = (filtered_value) => {
    let { formatted_data, soft_formatted_data } = this.state;
    if (!filtered_value || filtered_value == "") {
      return this.setState({ formatted_data: soft_formatted_data });
    }
    const filtered = formatted_data.filter(
      (item) =>
        item.id.includes(filtered_value) ||
        item.monitor.includes(filtered_value)
    );
    this.setState({ formatted_data: filtered });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  changeFilter2Start = () => {
    let { formatted_data, soft_formatted_data } = this.state;
    soft_formatted_data = formatted_data;
    let filter_storage = getFromLocalStorage("filter") || "[]";
    let new_data;
    let result_filter = [];
    filter_storage = JSON.parse(filter_storage);
    filter_storage = _.uniqBy(filter_storage);
    for (let index = 0; index < filter_storage.length; index++) {
      const element = filter_storage[index];
      switch (element) {
        case "up":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.status;
          });
          break;
        case "down":
          new_data = _.filter(soft_formatted_data, (o) => {
            return !o.status;
          });
          break;
        case "expiring_domain":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.expiring_domain;
          });
          break;
        case "expiring_ssl":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.expiring_ssl;
          });
          break;
        case "inactive":
          new_data = _.filter(soft_formatted_data, (o) => {
            return !o.status;
          });
          break;
        case "public":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.public;
          });
          break;
        case "private":
          new_data = _.filter(soft_formatted_data, (o) => {
            return !o.public;
          });
          break;
        case "https":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.type == new_value;
          });
          break;
        case "keyword":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.type == new_value;
          });
          break;
        case "ping":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.type == new_value;
          });
          break;
        case "port":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.type == new_value;
          });
          break;
        case "cronjob":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.type == new_value;
          });
          break;
        default:
          break;
      }
      if (new_data) result_filter.push(...new_data);
    }
    result_filter = _.uniqBy(result_filter, "id");
    saveToLocalStorage("filter", JSON.stringify(filter_storage));
    this.setState({
      checked_list: filter_storage,
      formatted_data:
        filter_storage && filter_storage.length != 0
          ? result_filter
          : soft_formatted_data,
    });
  };

  changeFilter2 = (new_value, e, first) => {
    let { formatted_data, soft_formatted_data } = this.state;
    if (first) soft_formatted_data = formatted_data;
    let filter_storage = getFromLocalStorage("filter") || "[]";
    let new_data;
    let result_filter = [];
    filter_storage = JSON.parse(filter_storage);
    if (e && e.target && e.target.checked) {
      filter_storage.push(new_value);
    } else {
      if (filter_storage.length != 1 && e && !e.target.checked) {
        filter_storage = filter_storage.filter((item) => item != new_value);
      } else {
        filter_storage = [];
      }
    }

    filter_storage = _.uniqBy(filter_storage);
    for (let index = 0; index < filter_storage.length; index++) {
      const element = filter_storage[index];
      switch (element) {
        case "up":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.status;
          });
          break;
        case "down":
          new_data = _.filter(soft_formatted_data, (o) => {
            return !o.status;
          });
          break;
        case "expiring_domain":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.expiring_domain;
          });
          break;
        case "expiring_ssl":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.expiring_ssl;
          });
          break;
        case "inactive":
          new_data = _.filter(soft_formatted_data, (o) => {
            return !o.status;
          });
          break;
        case "public":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.public;
          });
          break;
        case "private":
          new_data = _.filter(soft_formatted_data, (o) => {
            return !o.public;
          });
          break;
        case "https":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.type == new_value;
          });
          break;
        case "keyword":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.type == new_value;
          });
          break;
        case "ping":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.type == new_value;
          });
          break;
        case "port":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.type == new_value;
          });
          break;
        case "cronjob":
          new_data = _.filter(soft_formatted_data, (o) => {
            return o.type == new_value;
          });
          break;
        default:
          break;
      }
      if (new_data) result_filter.push(...new_data);
    }
    result_filter = _.uniqBy(result_filter, "id");
    saveToLocalStorage("filter", JSON.stringify(filter_storage));
    this.setState({
      checked_list: filter_storage,
      formatted_data: result_filter,
    });

    if (
      result_filter &&
      result_filter.length == 0 &&
      filter_storage &&
      filter_storage.length == 0
    ) {
      this.setState({
        formatted_data: soft_formatted_data,
      });
    }
    if (filter_storage.length == 1 && e && e.target && !e.target.checked) {
      saveToLocalStorage("filter", JSON.stringify([new_value]));
    }
  };

  changeFilter = (new_value, first) => {
    let { formatted_data, soft_formatted_data } = this.state;
    if (first) soft_formatted_data = formatted_data;
    let new_data;
    if (new_value == "default") {
      removeFromLocalStorage("dropdown");
    } else {
      saveToLocalStorage("dropdown", new_value);
    }
    switch (new_value) {
      case "default":
        new_data = soft_formatted_data;
        break;
      case "down":
        new_data = _.sortBy(soft_formatted_data, (o) => {
          return !!o.status;
        });
        break;
      case "up":
        new_data = _.sortBy(soft_formatted_data, (o) => {
          return o.status;
        });
        break;
      case "az":
        new_data = _.sortBy(soft_formatted_data, (o) => {
          return o.monitor.toLowerCase();
        });
        break;
      case "za":
        new_data = _.sortBy(soft_formatted_data, (o) => {
          return o.monitor.toLowerCase();
        }).reverse();
        break;
      case "newest":
        new_data = _.sortBy(
          soft_formatted_data,
          (o) => new Date(o.created_at_soft)
        );
        break;
      case "oldest":
        new_data = _.sortBy(
          soft_formatted_data,
          (o) => new Date(o.created_at_soft)
        ).reverse();
        break;
      case "control":
        new_data = _.sortBy(soft_formatted_data, (o) => parseInt(o.control));
        break;
      case "high":
        new_data = _.sortBy(soft_formatted_data, (o) =>
          parseInt(o.duration)
        ).reverse();
        break;
      case "low":
        new_data = _.sortBy(soft_formatted_data, (o) => parseInt(o.duration));
        break;
      default:
        break;
    }
    this.setState({ selected_value: new_value, formatted_data: new_data });
  };

  render() {
    let { product } = this.props;
    let { subscription } = product;
    let {
      formatted_data,
      loading,
      selected_value,
      soft_formatted_data,
      anchorEl,
    } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    if (loading) return <Page.Loader key="loader" />;
    if (
      !soft_formatted_data ||
      (soft_formatted_data && soft_formatted_data.length <= 0)
    )
      return (
        <Container style={{ padding: 0, textAlign: "center" }}>
          <Typography variant="h6" sx={{ color: "white", marginBottom: 1 }}>
            There is currently no server registered. Create and start using it
            now!
          </Typography>
          <AddButton text={"Start Monitoring"} />
        </Container>
      );
    return (
      <>
        <Box
          sx={{
            display: "flex",
            "@media screen and (max-width: 600px)": {
              display: "block",
              width: "100%",
            },
          }}
          flex={{ xs: "1 1 auto", md: "0 0 100%" }}
        >
          <FormControl
            sx={{
              mt: 1,
              marginTop: 0,
              justifyContent: "center",
              "@media screen and (max-width: 600px)": {
                width: "100%",
              },
            }}
          >
            <OutlinedInput
              id="outlined-adornment-amount"
              placeholder="Search by Monitor Name or Id"
              onChange={(e) => {
                let { value } = e.target;
                this.changeSearch(value);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <IconSearch
                    size={15}
                    sx={{
                      color: (theme) => theme.palette.primary.secondTextColor,
                    }}
                  />
                </InputAdornment>
              }
              className="custom-nonetextfield"
              sx={{
                caretColor: "#e0e0e0",
                color: "#e0e0e0",
                height: 35,
                fontSize: 13,
                paddingRight: 1,
                "& .MuiOutlinedInput-root": {
                  color: (theme) => theme.palette.primary.secondTextColor,
                },
                "& .MuiInputAdornment-root": {
                  color: (theme) => theme.palette.primary.secondTextColor,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#dfdfdfa1",
                  borderWidth: "thin",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#dfdfdfa1",
                  borderWidth: "thin",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#dfdfdfa1",
                  borderWidth: "thin",
                },
              }}
            />
          </FormControl>

          <div
            style={{
              marginBottom: "6px",
              marginLeft: "auto",
            }}
          >
            <FormControl
              sx={{
                "@media screen and (max-width: 600px)": {
                  width: "100%",
                },
                width: "200px",
                color: "#727881", // Daha karanlık gri ton
                marginTop: "9px",
                backgroundColor: "#131a25", // Arka plan rengi
                borderRadius: "4px", // Hafif yuvarlatılmış köşeler
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Hafif gölge
              }}
            >
              <Select
                sx={{
                  caretColor: "#727881", // İmleç rengi
                  color: "#c9c9c9", // Varsayılan yazı rengi
                  height: 38,
                  backgroundColor: "#131a25", // İç kısmın arka plan rengi
                  borderRadius: "4px", // Yuvarlatılmış kenarlar
                  "&:hover": {
                    backgroundColor: "#151f2f", // Hover durumunda biraz daha açık arka plan rengi
                    color: "#e0e0e0", // Hover durumunda yazı rengi
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#131a25", // Kenar çizgisi rengi
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#131a25", // Hover durumunda kenar rengi
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#131a25", // Odaklanmış durumda kenar rengi
                  },
                  ".MuiSelect-icon": {
                    color: "#727881",
                  },
                }}
                labelId="dropdown-label"
                value={selected_value}
                label="Sort Status"
                onChange={(e) => {
                  this.changeFilter(e.target.value);
                }}
              >
                {_.map(SortVariables, (e, i) => {
                  return (
                    <MenuItem
                      sx={{ color: "#c9c9c9" }} // Menü öğelerinin yazı rengi
                      value={e.value}
                      key={"dropdown-" + i}
                    >
                      {e.key}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>{" "}
            <div
              style={{
                marginTop: "6px",
                marginBottom: "6px",
                display: "inline-block",
              }}
            >
              <CustomButton
                text="Filter"
                aria-describedby={id}
                sx={{ marginRight: "5px" }}
                onClick={this.handleClick}
                icon={<IconAdjustmentsHorizontal />}
              />
            </div>
            {/* <AddButton
              text={
                "New Monitor" +
                ` (${soft_formatted_data.length}/${subscription.limit})`
              }
              component={Link}
              to={`/monitors/create`}
              sx={{
                "@media screen and (max-width: 600px)": {
                  marginTop: "10px",
                  width: "100%",
                },
                md: { width: "100%" },
              }}
            /> */}
            <CustomButton
              active={true}
              icon={<IconPlus />}
              text={
                "New Monitor" +
                ` (${soft_formatted_data.length}/${subscription.limit})`
              }
              component={Link}
              to={`/monitors/create`}
              sx={{
                "@media screen and (max-width: 600px)": {
                  marginTop: "10px",
                  width: "100%",
                },
                md: { width: "100%" },
              }}
            />
          </div>
        </Box>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            this.setState({ anchorEl: !anchorEl });
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <PopoverContent
            sx={{
              borderRadius: "0",
              background: "#151f2f",
              border: "none",
            }}
          >
            <div
              style={{
                marginBottom: "6px",
                marginLeft: "auto",
              }}
            >
              <FormGroup
                sx={{
                  "@media screen and (max-width: 600px)": {
                    width: "100%",
                  },
                  color: "#727881",
                }}
              >
                {_.map(FilterVariables, (e, i) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="success"
                          checked={
                            this.state.checked_list &&
                            this.state.checked_list.length > 0 &&
                            this.state.checked_list.includes(e.value)
                          }
                          onChange={(c) => {
                            this.changeFilter2(e.value, c);
                          }}
                        />
                      }
                      sx={{ color: "#c9c9c9" }}
                      label={e.key}
                    />
                  );
                })}
                <CustomButton
                  text="Reset"
                  aria-describedby={id}
                  sx={{ marginRight: "5px" }}
                  onClick={() => {
                    removeFromLocalStorage("filter");
                    this.setState({
                      checked_list: [],
                      formatted_data: soft_formatted_data,
                    });
                  }}
                  icon={<IconRestore />}
                />
              </FormGroup>
            </div>
          </PopoverContent>
        </Popover>
        <StatusTable formatted_data={formatted_data} />
      </>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
}))(Status);
