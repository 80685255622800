import React, { Component } from "react";
import { connect } from "react-redux";
import { Box, Typography } from "@mui/material";
import FeatureComparison from "../components/comparison/FeatureComparison";
import ParentComponent from "../components/comparison/ParentComponent";

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  componentDidMount() {
    document.title = "Pricing | Statusnap";
  }

  render() {
    return (
      <Box style={{ padding: 15 }}>
        <Box
          component="img"
          sx={{
            height: 233,
            width: 350,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="Statusnap"
          src={"./images/logo.svg"}
        />
        <div
          style={{ marginBottom: 30, display: "flex", alignItems: "center" }}
        >
          <div>
            <Typography
              variant="h4"
              sx={{ color: "#008000cd", display: "inline" }}
            >
              Great{" "}
            </Typography>
            <Typography variant="h4" sx={{ color: "white", display: "inline" }}>
              for individuals, small businesses, and large companies.
            </Typography>
            <div style={{ marginLeft: "auto", display: "table" }}>
              <span style={{ color: "#bbbbbbad" }}>
                Save ~25% with annual billing.
              </span>
            </div>
          </div>
        </div>
        <ParentComponent />
        <FeatureComparison />
      </Box>
    );
  }
}

export default connect((state) => ({
  user: state.access.user.loggedUserObj,
  product: state.product.undefined,
}))(Subscription);
